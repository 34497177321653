import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";
import './style.css';

function ProgramsModal({functionType, setFunctionType, currentProgram, setCurrentProgram, programsModalSetOpened}) {

    const [programName, setProgramName] = useState('');
    const [programNameRus, setProgramNameRus] = useState('');
    const [trainings, setTrainings] = useState([]);
    const [programWeekStart, setProgramWeekStart] = useState('');
    const [programWeekEnd, setProgramWeekEnd] = useState('');
    const [category, setCategory] = useState('');

    const [renderFlag, setRenderFlag] = useState(false);
    const [trainingIds, setTrainingIds] = useState([]);
    const [trainingSequence, setTrainingSequence] = useState([]);
    const [trainingItems, setTrainingItems] = useState([]);
    const [trainingDefaults, setTrainingsDefaults] = useState([]);

    const categoryOptions = [
        {key: 1, name: 'PREGNANCY', nameRus: 'Беременные'},
        {key: 2, name: 'POSTPARTUM', nameRus: 'Период восстановления'}];
    const requestMapping = api.requestMapping;
    const id = currentProgram.id;

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/trainings?category=' +
                    (category === '' ?  (currentProgram.category === undefined ? 'PREGNANCY' : currentProgram.category) : category),
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTrainingsDefaults(res.data.result);
                setTrainingSequence([]);
                setTrainingIds([]);
                setTrainingItems([]);
            } catch (error) {
                alert('Произошла ошибка при загрузке данных. ' + error);
            }
        })();
    }, [category]);

    useEffect(()=> {
        trainingsHandling();
    }, [renderFlag === true])

    function closeModalHandler() {
        programsModalSetOpened(false);
        setCurrentProgram('');
        setFunctionType('');
    }

    function trainingsHandling() {
        setTrainings(trainingIds);
        let arr = [];
        for (let i = 0; i < trainingSequence.length; i++) {
            arr.push(trainingDefaults[trainingSequence[i]]);
        }
        setTrainingItems(arr);
        setRenderFlag(false);
    }

    const programNameHandler = (programName) => {
        setProgramName(programName.target.value);
    }

    const programNameRusHandler = (programNameRus) => {
        setProgramNameRus(programNameRus.target.value);
    }

    const programStartHandler = (programWeekStart) => {
        setProgramWeekStart(programWeekStart.target.value);
    }

    const programEndHandler = (programWeekEnd) => {
        setProgramWeekEnd(programWeekEnd.target.value);
    }

    const categoryHandler = (category) => {
        setCategory(category.target.value);
    }

    const trainingAdding = (training) => {
        trainingSequence.push(training.target.value.split(" ")[0]);
        trainingIds.push(training.target.value.split(" ")[1]);
        setRenderFlag(true);
    }

    const trainingsDeleting = (training) => {
        trainingIds.splice(training, 1);
        trainingSequence.splice(training, 1);
        setRenderFlag(true);
    }

    const createProgram = async (programName, programNameRus, trainings, programWeekStart, programWeekEnd, category) => {
        await axios.post(requestMapping + '/training/admin/programs/new',
            { programName: programName, programNameRus: programNameRus, trainings: trainings,
                programWeekStart: programWeekStart, programWeekEnd: programWeekEnd, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Программа создана успешно!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editProgram = async (id, programName, programNameRus, trainings, programWeekStart, programWeekEnd, category) => {
        await axios.put(requestMapping + '/training/admin/programs',
            { id: id, programName: programName, programNameRus: programNameRus, trainings: trainings,
                programWeekStart: programWeekStart, programWeekEnd: programWeekEnd, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Программа "' + id + '" успешно отредактирована!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="programs-modal-wrapper">
            <div className="programs-modal-header">
                {functionType === 'create' ? <div className="programs-modal-name">Создать программу</div> :
                    <div className="programs-modal-name">Редактировать программу</div>
                }
                <div className="programs-modal-close-item">
                    <button className="programs-modal-close-button" onClick={closeModalHandler}>
                        <img className="programs-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="programs-modal-main">
                <div className="programs-modal-characteristics">
                    <div className="programs-container-label">Атрибуты</div>
                    <div className="programs-modal-container">
                        <div className="programs-handler">
                            <div className="programs-label">Название программы на латинице</div>
                            <input className="programs-input" onChange={programNameHandler}
                                   placeholder={currentProgram.programName} value={programName} type='text' required/>
                        </div>
                        <div className="programs-handler">
                            <div className="programs-label">Название программы на русском</div>
                            <input className="programs-input" onChange={programNameRusHandler}
                                   placeholder={currentProgram.programNameRus} value={programNameRus} type='text' required/>
                        </div>
                        <div className="programs-handler" id="programs-weeks">
                            <div className="programs-handler-short">
                                <label htmlFor="program-start">Старт программы</label>
                                <input id="program-start" className="programs-input-short" onChange={programStartHandler}
                                       placeholder={currentProgram.programWeekStart} value={programWeekStart} type='text' required/>
                            </div>
                            <div className="programs-handler-short">
                                <label htmlFor="program-end">Окончание программы</label>
                                <input id="program-end" className="programs-input-short" onChange={programEndHandler}
                                        placeholder={currentProgram.programWeekEnd} value={programWeekEnd} type='text' required/>
                            </div>
                        </div>
                        <div className="programs-handler">
                            <div className="programs-label">Категория</div>
                            <select className="programs-select" onChange={categoryHandler} defaultValue={currentProgram.category === '' ? categoryOptions[0] : currentProgram.category}>
                                {categoryOptions.map(item =>
                                    <option key={item.key}
                                            value={item.name}>
                                        {item.nameRus}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="programs-modal-trainings">
                    <div className="programs-container-label">Тренировки</div>
                    <div className="programs-modal-container">
                        <div className="programs-handler">
                            <div className="programs-label">Список тренировок</div>
                            <select className="programs-select" onChange={trainingAdding} defaultValue={"-"}>
                                <option value="-" disabled>-</option>
                                {trainingDefaults.map((item,index) =>
                                    <option key={index}
                                            value={index + " " + item.id}>
                                        {item.id + ' - ' + item.trainingTitle}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="programs-label">Выбранные</div>
                        <div className="programs-training-container">
                            {trainingItems.map((item, index) =>
                                <div className="programs-chosen-training">
                                    <div key={index} className="programs-chosen-training-item">{item.id + ' - ' + item.trainingTitle}</div>
                                    <div className="programs-training-delete" onClick={() => trainingsDeleting(index)}>
                                        <img className="programs-training-delete-icon" src={closeIcon}/>
                                    </div>
                                </div>)}
                        </div>
                        <div>
                            <div className="programs-label">Список тренировок</div>
                            <textarea className="programs-training-list" value={trainings} readOnly></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="programs-modal-footer">
                <button className="programs-modal-confirm" onClick={
                    functionType === 'create' ? () => createProgram(programName, programNameRus, trainings, programWeekStart, programWeekEnd,
                            category === '' ? 0 : category) :
                        () => editProgram(id,
                            (programName === '' ? currentProgram.programName : programName),
                            (programNameRus === '' ? currentProgram.programNameRus : programNameRus),
                            (trainings.length === 0 ? currentProgram.trainings : trainings),
                            (programWeekStart === '' ? currentProgram.programWeekStart : programWeekStart),
                            (programWeekEnd === '' ? currentProgram.programWeekEnd : programWeekEnd),
                            (category === '' ? currentProgram.category : category),
                        )}>Сохранить</button>
            </div>
        </div>
    );
}

export default ProgramsModal;