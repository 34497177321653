import React, {useEffect, useState} from "react";
import './style.css';
import closeIcon from '../../../../../templates/icons/close-window.png';
import axios from "axios";
import api from "../../../../../api.json";

function DetailedModal({functionType, setFunctionType, currentSymptom, setCurrentSymptom, detailedModalSetOpened}) {

    const requestMapping = api.requestMapping;
    const [detailedName, setDetailedName] = useState('');
    const [detailedNameRus, setDetailedNameRus] = useState('');
    const [commonList, setCommonList] = useState([]);
    const [priority, setPriority] = useState('');
    const [category, setCategory] = useState('');
    const [common, setCommon] = useState('');

    const id = currentSymptom.detailedId;
    const priorityOptions = [1, 2, 3, 4];
    const categoryOptions = [
        {key: 1, name: 'PREGNANCY', nameRus: 'Беременные'},
        {key: 2, name: 'POSTPARTUM', nameRus: 'Период восстановления'}];

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/diary/admin/common/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setCommonList(res.data.result);
            } catch (error) {
                alert('Произошла ошибка при загрузке данных. ' + error);
            }
        })();
    }, [])

    function closeModalHandler() {
        detailedModalSetOpened(false);
        setCurrentSymptom('');
        setFunctionType('');
    }

    const detailedNameHandler = (detailedName) => {
        setDetailedName(detailedName.target.value);
    }

    const detailedNameRusHandler = (detailedNameRus) => {
        setDetailedNameRus(detailedNameRus.target.value);
    }

    const priorityChange = (priority) => {
        setPriority(priority.target.value);
    }

    const commonChange = (common) => {
        setCommon(common.target.value);
    }

    const categoryChange = (category) => {
        setCategory(category.target.value);
    }

    const createSymptom = async (commonId, detailedName, detailedNameRus, priority, category) => {
        await axios.post( requestMapping + '/diary/admin/detailed/new',
            { commonId: commonId, detailedName: detailedName, detailedNameRus: detailedNameRus, priority: priority, category: category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert(response.data.result);
                closeModalHandler();
            })
            .catch((error) => window.alert('Произошла ошибка при выполнении запроса ' + error.response.data.message));
    }

    const editSymptom = async (id, commonId, detailedName, detailedNameRus, priority, category) => {
        await axios.patch(requestMapping + '/diary/admin/detailed/edit',
            { detailedId: id, commonId: commonId, detailedName: detailedName, detailedNameRus: detailedNameRus, priority: priority, category: category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert(response.data.result);
                closeModalHandler();
            })
            .catch((error) => window.alert('Произошла ошибка при выполнении запроса ' + error.response.data.message));
    }

    return (
        <div className="detailed-modal-wrapper">
            <div className="detailed-modal-header">
                {functionType === 'create' ? <div className="detailed-modal-name">Создать симптом</div> :
                    <div className="detailed-modal-name">Редактировать симптом</div>
                }
                <div className="detailed-modal-close-item">
                    <button className="detailed-modal-close-button" onClick={closeModalHandler}>
                        <img className="detailed-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="detailed-modal-main">
                <div className="detailed-group-handler">
                    <div className="detailed-label">Группа симптомов</div>
                    <select className="detailed-select" onChange={commonChange}>
                        {commonList.map(item =>
                            <option key={item.commonId}
                                    value={item.commonId}
                                    selected={item.commonId === currentSymptom.commonId}>
                                {item.commonId + ' - ' + item.commonNameRus}
                            </option>
                        )}
                    </select>
                </div>
                <div className="detailed-name-handler">
                    <div className="detailed-label">Название симптома на латинице (для БД)</div>
                    <input className="detailed-modal-input" onChange={detailedNameHandler} placeholder={currentSymptom.detailedName} value={detailedName} type='text' required/>
                </div>
                <div className="detailed-name-rus-handler">
                    <div className="detailed-label">Название симптома на русском языке</div>
                    <input className="detailed-modal-input" onChange={detailedNameRusHandler} placeholder={currentSymptom.detailedNameRus} value={detailedNameRus} type='text' required/>
                </div>
                <div className="detailed-priority-handler">
                    <div className="detailed-label">Приоритетность симтома</div>
                    <select className="detailed-select" onChange={priorityChange}>
                        {priorityOptions.map(item =>
                            <option key={item}
                                    value={item}
                                    selected={item === currentSymptom.priority}>
                                {item}
                            </option>
                        )}
                    </select>
                </div>
                <div className="detailed-category-handler">
                    <div className="detailed-label">Категория пользователя</div>
                    <select className="detailed-select" onChange={categoryChange}>
                        {categoryOptions.map(item =>
                            <option key={item.key}
                                    value={item.name}
                                    selected={item.name === currentSymptom.category}>
                                {item.nameRus}
                            </option>
                        )}
                    </select>
                </div>
            </div>
            <div className="detailed-modal-footer">
                <button className="detailed-modal-confirm" onClick={
                    functionType === 'create' ? () => createSymptom(
                            (common === '' ? 1 : common),
                            detailedName, detailedNameRus,
                            (priority === '' ? 4 : priority),
                            (category === '' ? 'PREGNANCY' : category)) :
                        () => editSymptom(id,
                            (common === '' ? currentSymptom.commonId : common),
                            (detailedName === '' ? currentSymptom.detailedName : detailedName),
                            (detailedNameRus === '' ? currentSymptom.detailedNameRus : detailedNameRus),
                            (priority === '' ? currentSymptom.priority : priority),
                            (category === '' ? currentSymptom.category : category))}>Сохранить</button>
            </div>
        </div>
    );
}

export default DetailedModal;

