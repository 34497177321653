import React from "react";
import './style.css'
import TableMapper from "../table-contents/mapping";

function TableLayout({table}){

    const mapper = new TableMapper();

    return (
        <div className="table-layout-wrapper">
            {mapper.tableMap(table)}
        </div>
    )
}

export default TableLayout;