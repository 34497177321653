import React, {useEffect, useState} from "react";
import axios from "axios";
import './style.css';
import editIcon from '../../../../../templates/icons/edit-icon.png';
import deleteIcon from '../../../../../templates/icons/delete-icon.png';
import Pagination from "../../../pagination";
import DetailedModal from "../modal";
import api from "../../../../../api.json";

function DetailedSymptoms() {

    const requestMapping = api.requestMapping;
    const [detailedList, setDetailedList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [detailedModalIsOpened, detailedModalSetOpened] = useState(false);
    const [currentDetailedSymptom, setCurrentDetailedSymptom] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(detailedList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/diary/admin/detailed/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setDetailedList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке подробных симптомов. ' + error);
            }
        })();
    }, [detailedModalIsOpened, currentDetailedSymptom])

    useEffect(() => {
        setItemList(detailedList.slice((currentPage - 1) * 10, currentPage * 10))
    },[detailedList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentDetailedSymptom(current);
        detailedModalSetOpened(state);
    }

    function deleteHandler(currentDetailedSymptom) {
        setCurrentDetailedSymptom(currentDetailedSymptom);
        if (window.confirm("Вы уверены, что хотите удалить подробный симптом с номером " + currentDetailedSymptom + "?")) {
            deleteSymptom(currentDetailedSymptom);
        } else {
            setCurrentDetailedSymptom('');
        }
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    const deleteSymptom = async (currentDetailedSymptom) => {
        await axios.delete(requestMapping + '/diary/admin/detailed/delete?id=' + currentDetailedSymptom,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert(response.data.result);
                setCurrentDetailedSymptom('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="detailed-table-inner-wrapper">
            <div className="detailed-table-modal-layout">
                <div className="detailed-table-inner-header">
                    <div className="detailed-table-header">
                        <div className="detailed-symptoms-id">id</div>
                        <div className="detailed-symptoms-group-id">Группа симптомов</div>
                        <div className="detailed-symptoms-name">Название (лат.)</div>
                        <div className="detailed-symptoms-name-rus">Название (рус.)</div>
                        <div className="detailed-symptoms-priority">Приоритет</div>
                        <div className="detailed-symptoms-category">Категория пользователя</div>
                        <div className="detailed-actions">Действия</div>
                    </div>
                </div>
                <div className="detailed-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.detailedId} className="detailed-table-item">
                            <div className="detailed-symptoms-id">{item.detailedId}</div>
                            <div className="detailed-symptoms-group-id">{item.commonId}</div>
                            <div className="detailed-symptoms-name">{item.detailedName}</div>
                            <div className="detailed-symptoms-name-rus">{item.detailedNameRus}</div>
                            <div className="detailed-symptoms-priority">{item.priority}</div>
                            <div className="detailed-symptoms-category">{item.category === 'POSTPARTUM' ? 'Восстановление' : 'Беременные'}</div>
                            <div className="detailed-actions-item">
                                <button className="detailed-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="detailed-actions-edit-icon" src={editIcon}/>
                                </button>
                                <button className="detailed-actions-item-delete-button" onClick={() => deleteHandler(item.detailedId)} >
                                    <img className="detailed-actions-delete-icon" src={deleteIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="detailed-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="detailed-table-create">
                        <button className="detailed-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать подробный симптом</button>
                    </div>
                </div>
            </div>
            {(detailedModalIsOpened) ? (
                <div className="detailed-table-modal-layout-alt">
                    <DetailedModal functionType={functionType}
                                   setFunctionType={openModalHandler}
                                   currentSymptom={currentDetailedSymptom}
                                   setCurrentSymptom={openModalHandler}
                                   detailedModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
        </div>
    )
}

export default DetailedSymptoms;