import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";
import "./style.css";

function TrainingsModal({functionType, setFunctionType, currentTraining, setCurrentTraining, trainingsModalSetOpened}) {

    const [preview, setPreview] = useState('');
    const [title, setTitle] = useState('');
    const [trainingTypes, setTrainingTypes] = useState([]);
    const [trainingType, setTrainingType] = useState('');
    const [category, setCategory] = useState('');

    const categoryOptions = [
        {key: 1, name: 'PREGNANCY', nameRus: 'Беременные'},
        {key: 2, name: 'POSTPARTUM', nameRus: 'Период восстановления'}];
    const requestMapping = api.requestMapping;
    const id = currentTraining.id;

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/training-types/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTrainingTypes(res.data.result);
            } catch (error) {
                alert('Произошла ошибка при загрузке данных. ' + error);
            }
        })();
    }, [])

    function closeModalHandler() {
        trainingsModalSetOpened(false);
        setCurrentTraining('');
        setFunctionType('');
    }

    const previewHandler = (preview) => {
        setPreview(preview.target.value);
    }

    const titleHandler = (title) => {
        setTitle(title.target.value);
    }

    const trainingTypeChange = (trainingType) => {
        setTrainingType(trainingType.target.value);
    }

    const categoryChange = (category) => {
        setCategory(category.target.value);
    }

    const createTraining = async (preview, title, trainingType, category) => {
        await axios.post(requestMapping + '/training/admin/trainings/new',
            { trainingPreview: preview, trainingTitle: title, trainingType: trainingType, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тренировка создана успешно!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editTraining = async (id, preview, title, trainingType, category) => {
        await axios.put(requestMapping + '/training/admin/trainings',
            { id: id, trainingPreview: preview, trainingTitle: title, trainingType: trainingType, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тренировка "' + id + '" успешно отредактирована!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="trainings-modal-wrapper">
            <div className="trainings-modal-header">
                {functionType === 'create' ? <div className="trainings-modal-name">Создать тренировку</div> :
                    <div className="trainings-modal-name">Редактировать тренировку</div>
                }
                <div className="trainings-modal-close-item">
                    <button className="trainings-modal-close-button" onClick={closeModalHandler}>
                        <img className="trainings-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="trainings-modal-main">
                <div className="trainings-modal-characteristics">
                    <div className="trainings-modal-container">
                        <div className="trainings-handler">
                            <div className="trainings-label">Ссылка на превью тренировки</div>
                            <input className="trainings-input" onChange={previewHandler}
                                   placeholder={currentTraining.trainingPreview} value={preview} type='text' required/>
                        </div>
                        <div className="trainings-handler">
                            <div className="trainings-label">Название тренировки</div>
                            <input className="trainings-input" onChange={titleHandler}
                                   placeholder={currentTraining.trainingTitle} value={title} type='text' required/>
                        </div>
                        <div className="trainings-handler">
                            <div className="trainings-label">Типы тренировок</div>
                            <select className="trainings-select" onChange={trainingTypeChange}  defaultValue={currentTraining.trainingType === '' ? trainingTypes[0] : currentTraining.trainingType}>
                                {trainingTypes.map(item =>
                                    <option key={item.id}
                                            value={item.id}>
                                        {item.id + ' - ' + item.nameRus}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="trainings-handler">
                            <div className="trainings-label">Категория</div>
                            <select className="trainings-select" onChange={categoryChange} defaultValue={currentTraining.category === '' ? categoryOptions[0] : currentTraining.category}>
                                {categoryOptions.map(item =>
                                    <option key={item.key}
                                            value={item.name}>
                                        {item.nameRus}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="trainings-modal-footer">
                <button className="trainings-modal-confirm" onClick={
                    functionType === 'create' ? () => createTraining(preview, title,
                            trainingType === '' ? 1 : trainingType,
                            category === '' ? 0 : category) :
                        () => editTraining(id,
                            (preview === '' ? currentTraining.trainingPreview : preview),
                            (title === '' ? currentTraining.trainingTitle : title),
                            (trainingType === '' ? currentTraining.trainingType : trainingType),
                            (category === '' ? currentTraining.category : category),
                            )}>Сохранить</button>
            </div>
        </div>
    );
}

export default TrainingsModal;