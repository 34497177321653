import React, {memo, useState} from "react";
import './style.css';
import hide_icon from '../../templates/icons/hide_pass.png';
import show_icon from '../../templates/icons/show_pass.png';
import PageLayout from "../page-layout";
import axios from "axios";
import api from '../../api.json';

function Form({setAuthorised, setName}) {

    const requestMapping = api.requestMapping;

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [visibility, setVisibility] = useState(false);

    const loginHandler = (login) => {
        setLogin(login.target.value);
    }

    const passwordHandler = (password) => {
        setPassword(password.target.value);
    }

    const visibilityHandler = (visibility) => {
        setVisibility(visibility);
    }

    const signIn = async (login, password) => {
        await axios.post(requestMapping + '/auth/login/super',
            { email: login, password: password },
            { headers: { 'Content-Type': 'application/json' } } )
            .then((response) => {
                localStorage.setItem("rocks-me-token", response.data.result.token)
                localStorage.setItem("rocks-me-name", login)
                setAuthorised(true);
                setName(login);
            })
            .catch((error) =>  {
                window.alert(error.response.data.message)
            });
    }

    return (
        <PageLayout>
            <div className="login-form">
                <h2 className="login-form-title">Авторизация</h2>
                <div className="login-form-item">
                    <div className="login-form-label">Логин</div>
                    <input onChange={loginHandler} value={login} type='text' required/>
                </div>
                <div className="login-form-item">
                    <div className="login-form-label">Пароль</div>
                    {(visibility) ?
                        <div className="login-form-password-wrapper">
                            <input className="login-form-input" onChange={passwordHandler} value={password}
                                   type='text' required/>
                            <img className="login-form-password-hide" onClick={() => visibilityHandler(false)} src={hide_icon}
                                 alt="mops"/>
                        </div> :
                        <div className="login-form-password-wrapper">
                            <input className="login-form-input" onChange={passwordHandler} value={password}
                                   type='password' required/>
                            <img className="login-form-password-show" onClick={() => visibilityHandler(true)} src={show_icon}
                                 alt="mops"/>
                        </div>}
                </div>
                <button className="login-form-button" onClick={() => signIn(login,password)}>Вход</button>
                <div className="login-form-copyright">&copy; rocks-me 2023</div>
            </div>
        </PageLayout>
    );
}

export default memo(Form);