import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";
import './style.css';

function PlansModal({functionType, setFunctionType, currentPlan, setCurrentPlan, plansModalSetOpened}) {

    const [planName, setPlanName] = useState('');
    const [planNameRus, setPlanNameRus] = useState('');
    const [programs, setPrograms] = useState([]);
    const [category, setCategory] = useState('');

    const [renderFlag, setRenderFlag] = useState(false);
    const [programsIds, setProgramsIds] = useState([]);
    const [programSequence, setProgramSequence] = useState([]);
    const [programItems, setProgramItems] = useState([]);
    const [programDefaults, setProgramDefaults] = useState([]);

    const categoryOptions = [
        {key: 1, name: 'PREGNANCY', nameRus: 'Беременные'},
        {key: 2, name: 'POSTPARTUM', nameRus: 'Период восстановления'}];
    const requestMapping = api.requestMapping;
    const id = currentPlan.id;

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/programs?category=' +
                    (category === '' ?  (currentPlan.category === undefined ? 'PREGNANCY' : currentPlan.category) : category),
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setProgramDefaults(res.data.result);
                setProgramSequence([]);
                setProgramsIds([]);
                setProgramItems([]);
            } catch (error) {
                alert('Произошла ошибка при загрузке данных. ' + error);
            }
        })();
    }, [category]);

    useEffect(()=> {
        programsHandling();
    }, [renderFlag === true])

    function closeModalHandler() {
        plansModalSetOpened(false);
        setCurrentPlan('');
        setFunctionType('');
    }

    function programsHandling() {
        setPrograms(programsIds);
        let arr = [];
        for (let i = 0; i < programSequence.length; i++) {
            arr.push(programDefaults[programSequence[i]]);
        }
        setProgramItems(arr);
        setRenderFlag(false);
    }

    const planNameHandler = (planName) => {
        setPlanName(planName.target.value);
    }

    const planNameRusHandler = (planNameRus) => {
        setPlanNameRus(planNameRus.target.value);
    }

    const categoryHandler = (category) => {
        setCategory(category.target.value);
    }

    const programAdding = (program) => {
        programSequence.push(program.target.value.split(" ")[0]);
        programsIds.push(program.target.value.split(" ")[1]);
        setRenderFlag(true);
    }

    const programDeleting = (program) => {
        programsIds.splice(program, 1);
        programSequence.splice(program, 1);
        setRenderFlag(true);
    }

    const createPlan = async (planName, planNameRus, programs, category) => {
        await axios.post(requestMapping + '/training/admin/plans/new',
            { planName: planName, planNameRus: planNameRus, programs: programs, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('План создан успешно!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editPlan = async (id, planName, planNameRus, programs, category) => {
        await axios.put(requestMapping + '/training/admin/plans',
            { id: id, planName: planName, planNameRus: planNameRus, programs: programs, category : category },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('План "' + id + '" успешно отредактирован!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="plans-modal-wrapper">
            <div className="plans-modal-header">
                {functionType === 'create' ? <div className="plans-modal-name">Создать план</div> :
                    <div className="plans-modal-name">Редактировать план</div>
                }
                <div className="plans-modal-close-item">
                    <button className="plans-modal-close-button" onClick={closeModalHandler}>
                        <img className="plans-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="plans-modal-main">
                <div className="plans-modal-characteristics">
                    <div className="plans-container-label">Атрибуты</div>
                    <div className="plans-modal-container">
                        <div className="plans-handler">
                            <div className="plans-label">Название плана на латинице</div>
                            <input className="plans-input" onChange={planNameHandler}
                                   placeholder={currentPlan.planName} value={planName} type='text' required/>
                        </div>
                        <div className="plans-handler">
                            <div className="plans-label">Название плана на русском</div>
                            <input className="plans-input" onChange={planNameRusHandler}
                                   placeholder={currentPlan.planNameRus} value={planNameRus} type='text' required/>
                        </div>
                        <div className="plans-handler">
                            <div className="plans-label">Категория</div>
                            <select className="plans-select" onChange={categoryHandler} defaultValue={currentPlan.category === '' ? categoryOptions[0] : currentPlan.category}>
                                {categoryOptions.map(item =>
                                    <option key={item.key}
                                            value={item.name}>
                                        {item.nameRus}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="plans-modal-programs">
                    <div className="plans-container-label">Программы</div>
                    <div className="plans-modal-container">
                        <div className="plans-handler">
                            <div className="plans-label">Список программ</div>
                            <select className="plans-select" onChange={programAdding} defaultValue={"-"}>
                                <option value="-" disabled>-</option>
                                {programDefaults.map((item,index) =>
                                    <option key={index}
                                            value={index + " " + item.id}>
                                        {item.id + ' - ' + item.programNameRus}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="plans-label">Выбранные</div>
                        <div className="plans-program-container">
                            {programItems.map((item, index) =>
                                <div className="plans-chosen-program">
                                    <div key={index} className="plans-chosen-program-item">{item.id + ' - ' + item.programNameRus}</div>
                                    <div className="plans-program-delete" onClick={() => programDeleting(index)}>
                                        <img className="plans-program-delete-icon" src={closeIcon}/>
                                    </div>
                                </div>)}
                        </div>
                        <div>
                            <div className="plans-label">Список тренировок</div>
                            <textarea className="plans-program-list" value={programs} readOnly></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="plans-modal-footer">
                <button className="plans-modal-confirm" onClick={
                    functionType === 'create' ? () => createPlan(planName, planNameRus, programs,
                            category === '' ? 0 : category) :
                        () => editPlan(id,
                            (planName === '' ? currentPlan.planName : planName),
                            (planNameRus === '' ? currentPlan.planNameRus : planNameRus),
                            (programs.length === 0 ? currentPlan.programs : programs),
                            (category === '' ? currentPlan.category : category)
                        )}>Сохранить</button>
            </div>
        </div>
    );
}

export default PlansModal;