import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import Pagination from "../../../pagination";
import TypesModal from "../modal";
import './style.css';

function TrainingTypes() {

    const requestMapping = api.requestMapping;
    const [typesList,setTypesList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [typesModalIsOpened, typesModalSetOpened] = useState(false);
    const [currentType, setCurrentType] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(typesList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/training-types/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTypesList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о типах тренировок. ' + error);
            }
        })();
    }, [typesModalIsOpened, currentType])

    useEffect(() => {
        setItemList(typesList.slice((currentPage - 1) * 10, currentPage * 10))
    },[typesList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentType(current);
        typesModalSetOpened(state);
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    return (
        <div className="types-table-inner-wrapper">
            <div className="types-table-modal-layout">
                <div className="types-table-inner-header">
                    <div className="types-table-header">
                        <div className="types-id">id</div>
                        <div className="types-name">Название (лат.)</div>
                        <div className="types-name-rus">Название (рус.)</div>
                        <div className="types-allowed-priority">Допустимая приоритетность</div>
                        <div className="types-interval-days">Интервал между тренировками</div>
                        <div className="types-actions">Действия</div>
                    </div>
                </div>
                <div className="types-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="types-table-item">
                            <div className="types-id">{item.id}</div>
                            <div className="types-name">{item.name}</div>
                            <div className="types-name-rus">{item.nameRus}</div>
                            <div className="types-allowed-priority">{item.allowedPriority}</div>
                            <div className="types-interval-days">{item.intervalBetween}</div>
                            <div className="types-actions-item">
                                <button className="types-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="types-actions-edit-icon" src={editIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="types-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="types-table-create">
                        <button className="types-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать тип тренировок</button>
                    </div>
                </div>
            </div>
            {(typesModalIsOpened) ? (
                <div className="types-table-modal-layout-alt">
                    <TypesModal functionType={functionType}
                                setFunctionType={openModalHandler}
                                currentType={currentType}
                                setCurrentType={openModalHandler}
                                typesModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
        </div>
    )
}

export default TrainingTypes;