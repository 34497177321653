import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import Pagination from "../../../pagination";
import "./style.css";
import PlansModal from "../modal";

function Plans() {

    const requestMapping = api.requestMapping;
    const [plansList, setPlansList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [plansModalIsOpened, plansModalSetOpened] = useState(false);
    const [currentPlan, setCurrentPlan] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(plansList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/plans/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setPlansList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о планах. ' + error);
            }
        })();
    }, [plansModalIsOpened, currentPlan])

    useEffect(() => {
        setItemList(plansList.slice((currentPage - 1) * 10, currentPage * 10))
    },[plansList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentPlan(current);
        plansModalSetOpened(state);
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    return (
        <div className="plans-table-inner-wrapper">
            <div className="plans-table-modal-layout">
                <div className="plans-table-inner-header">
                    <div className="plans-table-header">
                        <div className="plans-id-header">id</div>
                        <div className="plans-name-header">Название плана на латинице (для БД)</div>
                        <div className="plans-name-rus-header">Название плана на русском языке</div>
                        <div className="plans-trainings-header">Список программ</div>
                        <div className="plans-duration-header">Продолжительность плана</div>
                        <div className="plans-category-header">Категория пользователей</div>
                        <div className="plans-actions">Действия</div>
                    </div>
                </div>
                <div className="plans-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="plans-table-item">
                            <div className="plans-id">{item.id}</div>
                            <div className="plans-name">{item.planName}</div>
                            <div className="plans-name-rus">{item.planNameRus}</div>
                            <div className="plans-trainings">{item.programs.join(' , ')}</div>
                            <div className="plans-duration">{item.planDuration}</div>
                            <div className="plans-category">{item.category === 'POSTPARTUM' ? 'Восстановление' : 'Беременные'}</div>
                            <div className="plans-actions">
                                <button className="plans-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="plans-actions-edit-icon" src={editIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="plans-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="plans-table-create">
                        <button className="plans-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать план</button>
                    </div>
                </div>
            </div>
            {(plansModalIsOpened) ? (
                <div className="plans-table-modal-layout-alt">
                    <PlansModal functionType={functionType}
                                   setFunctionType={openModalHandler}
                                   currentPlan={currentPlan}
                                   setCurrentPlan={openModalHandler}
                                   plansModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
        </div>
    )
}

export default Plans;