import React, {useState} from "react";
import './style.css';
import closeIcon from '../../../../../templates/icons/close-window.png';
import axios from "axios";
import api from '../../../../../api.json';

function CommonModal({functionType, setFunctionType, currentSymptom, setCurrentSymptom, commonModalSetOpened}) {

    const [commonName, setCommonName] = useState('');
    const [commonNameRus, setCommonNameRus] = useState('');

    const requestMapping = api.requestMapping;
    const id = currentSymptom.commonId;

    function closeModalHandler() {
        commonModalSetOpened(false);
        setCurrentSymptom('');
        setFunctionType('');
    }

    const commonNameHandler = (commonName) => {
        setCommonName(commonName.target.value);
    }

    const commonNameRusHandler = (commonNameRus) => {
        setCommonNameRus(commonNameRus.target.value);
    }

    const createSymptom = async (commonName, commonNameRus) => {
        await axios.post(requestMapping + '/diary/admin/common/new',
            { commonName: commonName, commonNameRus: commonNameRus },
            { headers: {
                'Content-Type' : 'application/json',
                'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
            }})
            .then((response) => {
                window.alert(response.data.result);
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editSymptom = async (id, commonName, commonNameRus) => {
        await axios.patch(requestMapping + '/diary/admin/common/edit',
            { commonId: id, commonName: commonName, commonNameRus: commonNameRus },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert(response.data.result);
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="common-modal-wrapper">
            <div className="common-modal-header">
                {functionType === 'create' ? <div className="common-modal-name">Создать группу симптомов</div> :
                    <div className="common-modal-name">Редактировать группу симптомов</div>
                }
                <div className="common-modal-close-item">
                    <button className="common-modal-close-button" onClick={closeModalHandler}>
                        <img className="common-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="common-modal-main">
                <div className="common-name-handler">
                    <div className="common-name-label">Название группы на латинице (для БД)</div>
                    <input className="common-modal-input" onChange={commonNameHandler} placeholder={currentSymptom.commonName} value={commonName} type='text' required/>
                </div>
                <div className="common-name-rus-handler">
                    <div className="common-name-rus-label">Название группы на русском языке</div>
                    <input className="common-modal-input" onChange={commonNameRusHandler} placeholder={currentSymptom.commonNameRus} value={commonNameRus} type='text' required/>
                </div>
            </div>
            <div className="common-modal-footer">
                <button className="common-modal-confirm" onClick={
                    functionType === 'create' ? () => createSymptom(commonName, commonNameRus) :
                        () => editSymptom(id,
                            (commonName === '' ? currentSymptom.commonName : commonName),
                            (commonNameRus === '' ? currentSymptom.commonNameRus : commonNameRus))}>Сохранить</button>
            </div>
        </div>
    );
}

export default CommonModal;