import React, {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../../../pagination";
import './style.css';
import api from "../../../../../api.json";
import CredentialsModal from "../modal";

function Credentials() {

    const requestMapping = api.requestMapping;
    const [credentialsModalIsOpened, credentialsModalSetOpened] = useState(false);
    const [credentialsList, setCredentialsList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(credentialsList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get( requestMapping + '/users/admin/credentials/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setCredentialsList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных пользователей. ' + error);
            }
        })();
    }, [])

    useEffect(() => {
        setItemList(credentialsList.slice((currentPage - 1) * 10, currentPage * 10))
    },[credentialsList, currentPage])

    function handlePage(page) {
        setCurrentPage(page);
    }

    function openModalHandler(state) {
        credentialsModalSetOpened(state);
    }

    return (
        <div className="credentials-table-inner-wrapper">
            <div className="credentials-table-modal-layout">
                <div className="credentials-table-inner-header">
                    <div className="credentials-table-header">
                        <div className="credentials-id">id</div>
                        <div className="credentials-email">email</div>
                        <div className="credentials-role">Роль</div>
                        <div className="credentials-confirmed">Подтвержден ли email</div>
                    </div>
                </div>
                <div className="credentials-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="credentials-table-item">
                            <div className="credentials-id">{item.id}</div>
                            <div className="credentials-email">{item.email === null ? '---' : item.email}</div>
                            <div className="credentials-role">{item.role === 'ADMIN' ? 'Админ'
                                : (item.role === 'TRAINER' ? 'Тренер' : 'Пользователь')}</div>
                            <div className="credentials-confirmed">{item.emailConfirmed ? 'Да' : 'Нет' }</div>
                        </div>
                    )}
                </div>
                <div className="credentials-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="credentials-create">
                        <button className="credentials-create-button" onClick={() => openModalHandler(true)}>Создать привилегированного пользователя</button>
                    </div>
                </div>
            </div>
            {(credentialsModalIsOpened) ? (
                <div className="credentials-table-modal-layout-alt">
                    <CredentialsModal credentialsModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
        </div>
    )
}

export default Credentials;