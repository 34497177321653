import React, {useState} from "react";
import './style.css';
import axios from "axios";
import api from "../../../../../api.json";
import closeIcon from "../../../../../templates/icons/close-window.png";

function TagsModal ({functionType, setFunctionType, currentTag, setCurrentTag, tagsEditModalSetOpened}) {

    const [tagName, setTagName] = useState('');

    const requestMapping = api.requestMapping;
    const id = currentTag.id;

    function closeModalHandler() {
        tagsEditModalSetOpened(false);
        setCurrentTag('');
        setFunctionType('');
    }

    const tagNameHandler = (tagName) => {
        setTagName(tagName.target.value);
    }

    const createTag = async (tagName) => {
        await axios.post(requestMapping + '/training/admin/tags/new',
            { tagName: tagName },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тэг создан успешно!');
                closeModalHandler();
            })
            .catch((error) => {
                window.alert(error.response.data.message);
            })
    }

    const editTag = async (id, tagName) => {
        await axios.put(requestMapping + '/training/admin/tags',
            { id: id, tagName: tagName },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тэг "' + id + '" успешно отредактирован!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="tags-edit-modal-wrapper">
            <div className="tags-edit-modal-header">
                {functionType === 'create' ? <div className="tags-edit-modal-name">Создать тэг</div> :
                    <div className="tags-edit-modal-name">Редактировать тэг</div>
                }
                <div className="tags-edit-modal-close-item">
                    <button className="tags-edit-modal-close-button" onClick={closeModalHandler}>
                        <img className="tags-edit-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="tags-edit-modal-main">
                <div className="tags-edit-handler">
                    <div className="tags-edit-label">Название тэга</div>
                    <input className="tags-edit-input" onChange={tagNameHandler} placeholder={currentTag.tagName} value={tagName} type='text' required/>
                </div>
            </div>
            <div className="tags-edit-modal-footer">
                <button className="tags-edit-modal-confirm" onClick={
                    functionType === 'create' ? () => createTag(tagName) :
                        () => editTag(id, (tagName === '' ? currentTag.tagName : tagName))}>Сохранить</button>
            </div>
        </div>
    );
}

export default TagsModal;