import React, {memo} from "react";
import './style.css';

function PageLayout({children}) {
    return (
        <div className="page-layout">
            {children}
        </div>
    )
}

export default memo(PageLayout);