import React, {useEffect, useState} from "react";
import closeIcon from "../../../../../templates/icons/close-window.png";
import './style.css';
import axios from "axios";
import api from "../../../../../api.json";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import deleteIcon from "../../../../../templates/icons/delete-icon.png";
import TagsModal from "../tags-modal";


function Tags({tagsModalSetOpened}) {

    const requestMapping = api.requestMapping;
    const [tagsEditModalIsOpened, tagsEditModalSetOpened] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [functionType, setFunctionType] = useState('');

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/tags/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTagList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о тэгах. ' + error.response.data.message);
            }
        })();
    }, [tagsEditModalIsOpened, currentTag])

    function closeModalHandler() {
        tagsModalSetOpened(false);
    }

    function openTagsEditModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentTag(current);
        tagsEditModalSetOpened(state);
    }

    function deleteHandler(currentTag) {
        setCurrentTag(currentTag);
        if (window.confirm("Вы уверены, что хотите удалить тэг с id " + currentTag + "?")) {
            deleteTag(currentTag);
        } else {
            setCurrentTag('');
        }
    }

    const deleteTag = async (currentTag) => {
        await axios.delete(requestMapping + '/training/admin/tags/' + currentTag,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert("Тэг " + currentTag + " успешно удален!");
                setCurrentTag('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="tags-modal-outer-wrapper">
            <div className="tags-modal-wrapper">
                <div className="tags-modal-header">
                    <div className="tags-modal-name">Тэги</div>
                    <div className="tags-modal-close-item">
                        <button className="tags-modal-close-button" onClick={closeModalHandler}>
                            <img className="tags-modal-close-icon" src={closeIcon}/>
                        </button>
                    </div>
                </div>
                <div className="tags-modal-main">
                    <div className="tags-modal-subheader">
                        <div className="tags-id-subheader">id</div>
                        <div className="tags-name-subheader">Название</div>
                        <div className="tags-actions-subheader">Действия</div>
                    </div>
                    <div className="tags-modal-content">
                        {(tagList.map((item) =>
                            <div className="tags-item" key={item.id}>
                                <div className="tags-id">{item.id}</div>
                                <div className="tags-name">{item.tagName}</div>
                                <div className="tags-actions-item">
                                    <button className="tags-actions-item-edit-button" onClick={() => openTagsEditModalHandler('edit', true, item)}>
                                        <img className="tags-actions-edit-icon" src={editIcon}/>
                                    </button>
                                    <button className="tags-actions-item-delete-button" onClick={() => deleteHandler(item.id)} >
                                        <img className="tags-actions-delete-icon" src={deleteIcon}/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="tags-modal-button-wrapper">
                    <div className="tags-modal-button-block">
                        <button className="tags-modal-button" onClick={() => openTagsEditModalHandler('create', true, '')}>Создать тэг</button>
                    </div>
                </div>
            </div>
            {(tagsEditModalIsOpened) ? (
                <div className="tags-modal-wrapper-alt">
                    <TagsModal functionType={functionType}
                               setFunctionType={openTagsEditModalHandler}
                               currentTag={currentTag}
                               setCurrentTag={openTagsEditModalHandler}
                               tagsEditModalSetOpened={openTagsEditModalHandler}/>/>
                </div>
            ) : ''}
        </div>
    );
}

export default Tags;