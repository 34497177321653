import React, {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../../../pagination";
import './style.css';
import api from "../../../../../api.json";
import SearchByUserId from "../../../search-by-user-id";

function TrainingProgress() {

    const requestMapping = api.requestMapping;
    const [trainingProgressList, setTrainingProgressList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentUser, setCurrentUser] = useState('');
    const [searchState, setSearchState] = useState(false);

    let totalPages = Math.ceil(trainingProgressList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get( requestMapping + '/training/admin/training-progresses/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTrainingProgressList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных. ' + error);
            }
        })();
    }, [currentUser === '']);

    const getTrainingProgressByUserId = async (currentUserId) => {
        await axios.get( requestMapping + '/training/admin/training-progresses/' + currentUserId,
            { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                setTrainingProgressList(response.data.result);
            })
            .catch((error) => {
                setSearchState(false);
                setCurrentUser('');
                window.alert('Произошла ошибка при выполнении запроса. ' + error.response.data.code + " " +  error.response.data.message);
            });
    }

    useEffect(() => {
        setItemList(trainingProgressList.slice((currentPage - 1) * 10, currentPage * 10))
    },[trainingProgressList, currentPage])

    function handlePage(page) {
        setCurrentPage(page);
    }

    function handleSearch() {
        getTrainingProgressByUserId(currentUser);
        setSearchState(true);
    }

    return (
        <div className="training-progress-table-inner-wrapper">
            <div className="training-progress-table-modal-layout">
                <div className="training-progress-table-inner-header">
                    <div className="training-progress-table-header">
                        <div className="training-progress-id">id</div>
                        <div className="training-progress-user-id">id пользователя</div>
                        <div className="training-progress-program-id">id программы</div>
                        <div className="training-progress-training-type">Тип тренировки</div>
                        <div className="training-progress-training-list">Список тренировок</div>
                        <div className="training-progress-training-stage">Этап программы</div>
                        <div className="training-progress-current-training-id">Следующая тренировка</div>
                        <div className="training-progress-current-training-date">Дата следующей тренировки</div>
                    </div>
                </div>
                <div className="training-progress-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="training-progress-table-item">
                            <div className="training-progress-id">{item.id}</div>
                            <div className="training-progress-user-id">{item.userId}</div>
                            <div className="training-progress-program-id">{item.programId}</div>
                            <div className="training-progress-training-type">{item.trainingType}</div>
                            <div className="training-progress-training-list">{item.trainingList}</div>
                            <div className="training-progress-training-stage">{item.trainingStage}</div>
                            <div className="training-progress-current-training-id">{item.currentTrainingId}</div>
                            <div className="training-progress-current-training-date">{item.currentTrainingDate.slice(0, 10)}</div>
                        </div>
                    )}
                </div>
                <div className="training-progress-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <SearchByUserId currentUser={currentUser}
                                    setCurrentUser={setCurrentUser}
                                    handleSearch={handleSearch}
                                    searchState={searchState}
                                    setSearchState={setSearchState}/>
                </div>
            </div>
        </div>
    )
}

export default TrainingProgress;