import React, {useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";
import './style.css';

function TypesModal ({functionType, setFunctionType, currentType, setCurrentType, typesModalSetOpened}) {

    const [typesName, setTypesName] = useState('');
    const [typesNameRus, setTypesNameRus] = useState('');
    const [priority, setPriority] = useState('');
    const [interval, setInterval] = useState('');

    const priorityOptions = [1, 2, 3, 4];
    const requestMapping = api.requestMapping;
    const id = currentType.id;

    function closeModalHandler() {
        typesModalSetOpened(false);
        setCurrentType('');
        setFunctionType('');
    }

    const typesNameHandler = (typesName) => {
        setTypesName(typesName.target.value);
    }

    const intervalHandler = (interval) => {
        setInterval(interval.target.value);
    }

    const priorityChange = (priority) => {
        setPriority(priority.target.value);
    }

    const typesNameRusHandler = (typesNameRus) => {
        setTypesNameRus(typesNameRus.target.value);
    }

    const createType = async (typesName, typesNameRus, priority, interval) => {
        await axios.post(requestMapping + '/training/admin/training-types/new',
            { name: typesName, nameRus: typesNameRus, allowedPriority: priority, intervalBetween: interval },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тип тренировки создан успешно!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editType = async (id, typesName, typesNameRus, priority, interval) => {
        await axios.put(requestMapping + '/training/admin/training-types',
            { id: id, name: typesName, nameRus: typesNameRus, allowedPriority: priority, intervalBetween: interval },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Тип тренировки "' + typesNameRus + '" успешно отредактирован!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="types-modal-wrapper">
            <div className="types-modal-header">
                {functionType === 'create' ? <div className="types-modal-name">Создать тип тренировок</div> :
                    <div className="types-modal-name">Редактировать группу симптомов</div>
                }
                <div className="types-modal-close-item">
                    <button className="types-modal-close-button" onClick={closeModalHandler}>
                        <img className="types-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="types-modal-main">
                <div className="types-handler">
                    <div className="types-label">Название типа на латинице (для БД)</div>
                    <input className="types-input" onChange={typesNameHandler} placeholder={currentType.name} value={typesName} type='text' required/>
                </div>
                <div className="types-handler">
                    <div className="types-label">Название типа на русском языке</div>
                    <input className="types-input" onChange={typesNameRusHandler} placeholder={currentType.nameRus} value={typesNameRus} type='text' required/>
                </div>
                <div className="types-handler">
                    <div className="types-label">Допустимая приоритетность</div>
                    <select className="types-select" onChange={priorityChange} defaultValue={currentType === '' ? priorityOptions[0] : currentType.allowedPriority}>
                        {priorityOptions.map(item =>
                            <option key={item}
                                    value={item}>
                                {item}
                            </option>
                        )}
                    </select>
                </div>
                <div className="types-handler">
                    <div className="types-label">Интервал между тренировками</div>
                    <input className="types-input" onChange={intervalHandler} placeholder={currentType.intervalBetween} value={interval} type='text' required/>
                </div>
            </div>
            <div className="types-modal-footer">
                <button className="types-modal-confirm" onClick={
                    functionType === 'create' ? () => createType(typesName, typesNameRus,
                            priority === '' ? 4 : priority, interval) :
                        () => editType(id,
                            (typesName === '' ? currentType.name : typesName),
                            (typesNameRus === '' ? currentType.nameRus : typesNameRus),
                            (priority === '' ? currentType.allowedPriority : priority),
                            (interval === '' ? currentType.intervalBetween : interval))}>Сохранить</button>
            </div>
        </div>
    );
}

export default TypesModal;