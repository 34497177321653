import React, {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../../../pagination";
import './style.css';
import api from "../../../../../api.json";

function Notes() {

    const requestMapping = api.requestMapping;
    const [notesList, setNotesList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(notesList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get( requestMapping + '/diary/admin/notes/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setNotesList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных дневника самочувствия. ' + error);
            }
        })();
    }, [])

    useEffect(() => {
        setItemList(notesList.slice((currentPage - 1) * 10, currentPage * 10))
    },[notesList, currentPage])

    function handlePage(page) {
        setCurrentPage(page);
    }

    return (
        <div className="notes-table-inner-wrapper">
            <div className="notes-table-modal-layout">
                <div className="notes-table-inner-header">
                    <div className="notes-table-header">
                        <div className="notes-id">id записи</div>
                        <div className="notes-user-id">id пользователя</div>
                        <div className="notes-date-of-fill">Дата заполнения</div>
                        <div className="notes-symptoms-detailed">Список симптомов</div>
                        <div className="notes-priority">Приоритет</div>
                    </div>
                </div>
                <div className="notes-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="notes-table-item">
                            <div className="notes-id">{item.id}</div>
                            <div className="notes-user-id">{item.userId}</div>
                            <div className="notes-date-of-fill">{'Дата ' + (item.localDateTime.slice(0, 10)) + '; Время: ' + (item.localDateTime.slice(11, 19)) }</div>
                            <div className="notes-symptoms-detailed">{item.symptoms.join(', ')}</div>
                            <div className="notes-priority">{item.priority}</div>
                        </div>
                    )}
                </div>
                <div className="notes-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                </div>
            </div>
        </div>
    )
}

export default Notes;