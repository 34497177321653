import React, {useState} from "react";
import TableList from "../table-list";
import TableLayout from "../table-layout";
import './style.css';

function MainContent({current}) {

    const [table, setTable] = useState('');

    function tableHandler(tableName) {
        setTable(tableName);
    }

    return (
        <div className="main-content-wrapper">
            <TableList current = {current} table = {table} setTable = {tableHandler}/>
            <TableLayout current = {current} table = {table}/>
        </div>
    );
}

export default MainContent;