import React, {useState} from "react";
import PageLayout from "../page-layout";
import Header from "../header";
import mainTheme from "../../templates/img/main.jpg";
import MainContent from "../main-content";
import './style.css';

function AppMain({setAuthorized}) {
    const [current, setCurrent] = useState('');

    function moduleHandler (module) {
        setCurrent(module);
    }

    function authorizationHandler(status) {
        setAuthorized(status);
    }

    return (
        <PageLayout>
            <Header current={current} setCurrent={moduleHandler} setAuthorized={authorizationHandler}/>
            {current === '' ?
                <div className="main-theme-wrapper">
                    <img className="main-image" src={mainTheme}/>
                </div> :
                <MainContent current={current}/>
            }
        </PageLayout>
    );
}

export default AppMain;