import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import Pagination from "../../../pagination";
import './style.css';
import ProgramsModal from "../modal";

function Programs() {

    const requestMapping = api.requestMapping;
    const [programsList, setProgramsList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [programsModalIsOpened, programsModalSetOpened] = useState(false);
    const [currentProgram, setCurrentProgram] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(programsList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/programs/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setProgramsList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о программах. ' + error);
            }
        })();
    }, [programsModalIsOpened, currentProgram])

    useEffect(() => {
        setItemList(programsList.slice((currentPage - 1) * 10, currentPage * 10))
    },[programsList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentProgram(current);
        programsModalSetOpened(state);
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    return (
        <div className="programs-table-inner-wrapper">
            <div className="programs-table-modal-layout">
                <div className="programs-table-inner-header">
                    <div className="programs-table-header">
                        <div className="programs-id-header">id</div>
                        <div className="programs-name-header">Название программы на латинице (для БД)</div>
                        <div className="programs-name-rus-header">Название программы на русском языке</div>
                        <div className="programs-trainings-header">Список тренировок</div>
                        <div className="programs-start-header">Начало<br/>(нед.)</div>
                        <div className="programs-end-header">Конец<br/>(нед.)</div>
                        <div className="programs-category-header">Категория пользователей</div>
                        <div className="programs-actions">Действия</div>
                    </div>
                </div>
                <div className="programs-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="programs-table-item">
                            <div className="programs-id">{item.id}</div>
                            <div className="programs-name">{item.programName}</div>
                            <div className="programs-name-rus">{item.programNameRus}</div>
                            <div className="programs-trainings">{item.trainings.join(' , ')}</div>
                            <div className="programs-start">{item.programWeekStart}</div>
                            <div className="programs-end">{item.programWeekEnd}</div>
                            <div className="programs-category">{item.category === 'POSTPARTUM' ? 'Восстановление' : 'Беременные'}</div>
                            <div className="programs-actions">
                                <button className="programs-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="programs-actions-edit-icon" src={editIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="programs-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="programs-table-create">
                        <button className="programs-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать программу</button>
                    </div>
                </div>
            </div>
            {(programsModalIsOpened) ? (
                <div className="programs-table-modal-layout-alt">
                    <ProgramsModal functionType={functionType}
                                    setFunctionType={openModalHandler}
                                    currentProgram={currentProgram}
                                    setCurrentProgram={openModalHandler}
                                    programsModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
        </div>
    )
}

export default Programs;