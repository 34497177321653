import React, {useEffect, useState} from "react";
import api from "../../../../../../api.json";
import axios from "axios";
import backIcon from "../../../../../../templates/icons/back-icon.png";
import "./style.css";
import editIcon from "../../../../../../templates/icons/edit-icon.png";
import deleteIcon from "../../../../../../templates/icons/delete-icon.png";
import PartsModal from "../modal";

function ModulesEditModal({currentTraining, setCurrentTraining, modulesModalSetOpened}) {

    const requestMapping = api.requestMapping;
    const [modulesList, setModulesList] = useState([]);
    const [exercisesList, setExercisesList] = useState([]);
    const [currentModule, setCurrentModule] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [partsModalIsOpened, partsModalSetOpened] = useState(false);

    function closeModalHandler() {
        modulesModalSetOpened(false);
        setCurrentTraining('');
    }

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/trainings/' + currentTraining.id  + '/modules',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setModulesList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о модуле тренировки. ' + error.response.data.message);
            }
        })();
    }, [modulesModalSetOpened, currentTraining, partsModalIsOpened, currentModule])

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/exercises/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setExercisesList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных об упражнениях. ' + error.response.data.message);
            }
        })();
    }, [])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentModule(current);
        partsModalSetOpened(state);
    }

    function deleteHandler(currentModule) {
        setCurrentModule(currentModule);
        if (window.confirm("Вы уверены, что хотите удалить модуль с id " + currentModule + "?")) {
            deleteModule(currentModule);
        } else {
            setCurrentModule('');
        }
    }

    function getExerciseTitle (id) {
        for (let i = 0; i < exercisesList.length; i++) {
            if (exercisesList[i].id == id) {
                return exercisesList[i].title;
            }
        }
        return '';
    }

    const deleteModule = async (currentModule) => {
        await axios.delete(requestMapping + '/training/admin/modules/' + currentModule,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert("Модуль " + currentModule + " успешно удален!");
                setCurrentModule('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="modules-modal-inner-wrapper">
            {(!partsModalIsOpened) ? (
            <div className="modules-table-modal-layout">
                <div className="modules-table-outer-header" >
                    <button className="modules-modal-back-button" onClick={closeModalHandler}>
                        <img className="modules-modal-back-icon" src={backIcon}/>
                    </button>
                    <div className="modules-training-name">Упражнения тренировки {currentTraining.trainingTitle}</div>
                </div>
                <div className="modules-table-inner-header">
                    <div className="modules-exercise-header">Основное упражнение</div>
                    <div className="modules-symptoms-header">Список симптомов</div>
                    <div className="modules-alternative-exercise-header">Альтернативное упражнение</div>
                    <div className="modules-actions-header">Действия</div>
                </div>
                <div className="modules-table-inner-main">
                    {modulesList.map((item) =>
                        <div key={item.id} className="modules-table-item">
                            <div className="modules-exercise-item">
                                { getExerciseTitle(item.exerciseId) !=='' ?
                                    item.exerciseId + ' - ' + getExerciseTitle(item.exerciseId) :
                                    item.exerciseId }
                            </div>
                            <div className="modules-symptoms-item">{item.symptoms.join(', ')}</div>
                            <div className="modules-alternative-exercise-item">
                                { getExerciseTitle(item.alternativeExerciseId) !=='' ?
                                    item.alternativeExerciseId + ' - ' + getExerciseTitle(item.alternativeExerciseId) :
                                    item.alternativeExerciseId }
                            </div>
                            <div className="modules-actions-item">
                                <button className="modules-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="modules-actions-edit-icon" src={editIcon}/>
                                </button>
                                <button className="modules-actions-item-delete-button" onClick={() => deleteHandler(item.id)} >
                                    <img className="modules-actions-delete-icon" src={deleteIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="modules-table-inner-footer">
                    <div className="modules-table-create">
                        <button className="modules-table-create-button" onClick={() => openModalHandler('create', true, '')}>Добавить модуль</button>
                    </div>
                </div>
            </div>
                ) : (
                    <PartsModal functionType={functionType}
                                setFunctionType={openModalHandler}
                                currentModule={currentModule}
                                setCurrentModule={openModalHandler}
                                currentTrainingId = {currentTraining.id}
                                currentCategory = {currentTraining.category}
                                partsModalSetOpened={openModalHandler}/>
                )}
        </div>
    );
}

export default ModulesEditModal;