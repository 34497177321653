import React, {useEffect, useState} from "react";
import axios from "axios";
import './style.css';
import editIcon from '../../../../../templates/icons/edit-icon.png';
import deleteIcon from '../../../../../templates/icons/delete-icon.png';
import CommonModal from "../modal";
import Pagination from "../../../pagination";
import api from '../../../../../api.json';

function CommonSymptoms() {

    const requestMapping = api.requestMapping;
    const [commonList, setCommonList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [commonModalIsOpened, commonModalSetOpened] = useState(false);
    const [currentSymptom, setCurrentSymptom] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(commonList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/diary/admin/common/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setCommonList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о подробных симптомах. ' + error.message);
            }
        })();
    }, [commonModalIsOpened, currentSymptom])

    useEffect(() => {
        setItemList(commonList.slice((currentPage - 1) * 10, currentPage * 10))
    },[commonList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentSymptom(current);
        commonModalSetOpened(state);
    }

    function deleteHandler(currentSymptom) {
        setCurrentSymptom(currentSymptom);
        if (window.confirm("Вы уверены, что хотите удалить группу симптомов с номером " + currentSymptom + "?")) {
            deleteSymptom(currentSymptom);
        } else {
            setCurrentSymptom('');
        }
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    const deleteSymptom = async (currentSymptom) => {
        await axios.delete(requestMapping + '/diary/admin/common/delete?id=' + currentSymptom,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert(response.data.result);
                setCurrentSymptom('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="common-table-inner-wrapper">
            <div className="common-table-modal-layout">
                <div className="common-table-inner-header">
                    <div className="common-table-header">
                        <div className="common-symptoms-id">id</div>
                        <div className="common-symptoms-name">Название (лат.)</div>
                        <div className="common-symptoms-name-rus">Название (рус.)</div>
                        <div className="common-actions">Действия</div>
                    </div>
                </div>
                <div className="common-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.commonId} className="common-table-item">
                            <div className="common-symptoms-id">{item.commonId}</div>
                            <div className="common-symptoms-name">{item.commonName}</div>
                            <div className="common-symptoms-name-rus">{item.commonNameRus}</div>
                            <div className="common-actions-item">
                                <button className="common-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="common-actions-edit-icon" src={editIcon}/>
                                </button>
                                <button className="common-actions-item-delete-button" onClick={() => deleteHandler(item.commonId)} >
                                    <img className="common-actions-delete-icon" src={deleteIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="common-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="common-table-create">
                        <button className="common-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать группу симптомов</button>
                    </div>
                </div>
            </div>
            {(commonModalIsOpened) ? (
                    <div className="common-table-modal-layout-alt">
                        <CommonModal functionType={functionType} setFunctionType={openModalHandler} currentSymptom={currentSymptom} setCurrentSymptom={openModalHandler} commonModalSetOpened={openModalHandler}/>
                    </div>
            ) : '' }
        </div>
    )
}

export default CommonSymptoms;