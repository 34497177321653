import './style.css';
import axios from "axios";
import api from '../../api.json';

function Profile({setAuthorized}) {

    const name = localStorage.getItem("rocks-me-name");
    const requestMapping = api.requestMapping;

    async function logout () {
        try {
            await axios.post(requestMapping + '/auth/logout').then(
                () => {
                    localStorage.removeItem("rocks-me-token");
                    localStorage.removeItem("rocks-me-name");
                    setAuthorized(false);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="profile-wrapper">
            <div className="profile-name">{name !== '' ? <p>{name}</p> : ''}</div>
            <div className="profile-logout-button-wrapper">
                <button className="profile-logout-button" onClick={logout}>Выйти</button>
            </div>
        </div>
    )
}

export default Profile;