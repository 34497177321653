import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import deleteIcon from "../../../../../templates/icons/delete-icon.png";
import Pagination from "../../../pagination";
import ExercisesModal from "../exercises-modal";
import './style.css';
import Tags from "../tags";
import copyIcon from "../../../../../templates/icons/copy-link.png";

function Exercises() {

    const requestMapping = api.requestMapping;
    const [exercisesList, setExercisesList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [exercisesModalIsOpened, exercisesModalSetOpened] = useState(false);
    const [currentExercise, setCurrentExercise] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [tagsModalIsOpened, tagsModalSetOpened] = useState(false);

    let totalPages = Math.ceil(exercisesList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/exercises/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setExercisesList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных об упражнениях. ' + error.response.data.message);
            }
        })();
    }, [exercisesModalIsOpened, tagsModalIsOpened, currentExercise])

    useEffect(() => {
        setItemList(exercisesList.slice((currentPage - 1) * 10, currentPage * 10))
    },[exercisesList, currentPage])

    function openExerciseModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentExercise(current);
        exercisesModalSetOpened(state);
    }

    function openTagsModalHandler(state) {
        tagsModalSetOpened(state);
    }

    function deleteHandler(currentExercise) {
        setCurrentExercise(currentExercise);
        if (window.confirm("Вы уверены, что хотите удалить упражнение с id " + currentExercise + "?")) {
            deleteExercise(currentExercise);
        } else {
            setCurrentExercise('');
        }
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    function copyLinkHandler(link) {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                alert("Скопировано.");
            })
            .catch(() => {
                alert("Произошла ошибка.");
            });
    }

    const deleteExercise = async (currentExercise) => {
        await axios.delete(requestMapping + '/training/admin/exercises/' + currentExercise,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert("Упражнение " + currentExercise + " успешно удалено!");
                setCurrentExercise('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="exercises-table-inner-wrapper">
            <div className="exercises-table-modal-layout">
                <div className="exercises-table-inner-header">
                    <div className="exercises-table-header">
                        <div className="exercises-id-header">id</div>
                        <div className="exercises-tag-header">Тэг</div>
                        <div className="exercises-preview-header">Ссылка на превью</div>
                        <div className="exercises-title-header">Название</div>
                        <div className="exercises-description-header">Описание</div>
                        <div className="exercises-video-link-header">Ссылка на видео</div>
                        <div className="exercises-duration-header">Время (сек.)</div>
                        <div className="exercises-actions">Действия</div>
                    </div>
                </div>
                <div className="exercises-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="exercises-table-item">
                            <div className="exercises-id">{item.id}</div>
                            <div className="exercises-tag">{item.tagName}</div>
                            <div className="exercises-preview">
                                <div className="exercises-preview-link">
                                    <a className="preview-link-text" href={item.preview} target="_blank">
                                        {(item.preview == '') ? '' : 'Ссылка'}</a>
                                </div>
                                {(item.preview == '') ? '' :
                                    <div className="exercises-preview-copy-item">
                                        <button className="exercises-preview-copy-button"
                                                onClick={() => copyLinkHandler(item.preview)}>
                                            <img className="exercises-preview-copy-icon" src={copyIcon}/>
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="exercises-title">{item.title}</div>
                            <div className="exercises-description">{item.description}</div>
                            <div className="exercises-video-link">
                                <div className="exercises-video-text-link">
                                    <a className="video-link-text" href={item.videoLink} target="_blank">
                                        {(item.videoLink == '') ? '' : 'Ссылка'}</a>
                                </div>
                                {(item.videoLink == '') ? '' :
                                    <div className="exercises-video-copy-item">
                                        <button className="exercises-video-copy-button"
                                                onClick={() => copyLinkHandler(item.videoLink)}>
                                            <img className="exercises-video-copy-icon" src={copyIcon}/>
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="exercises-duration">{item.duration}</div>
                            <div className="exercises-actions-item">
                                <button className="exercises-actions-item-edit-button" onClick={() => openExerciseModalHandler('edit', true, item)}>
                                    <img className="exercises-actions-edit-icon" src={editIcon}/>
                                </button>
                                <button className="exercises-actions-item-delete-button" onClick={() => deleteHandler(item.id)} >
                                    <img className="exercises-actions-delete-icon" src={deleteIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="exercises-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="exercises-buttons">
                        <div className="exercises-button-block">
                            <button className="exercises-button" onClick={() => openTagsModalHandler(true)} >Список тэгов</button>
                        </div>
                        <div className="exercises-button-block">
                            <button className="exercises-button" onClick={() => openExerciseModalHandler('create', true, '')}>Создать упражнение</button>
                        </div>
                    </div>
                </div>
            </div>
            {(exercisesModalIsOpened) ? (
                <div className="exercises-table-modal-layout-alt">
                    <ExercisesModal functionType={functionType}
                                   setFunctionType={openExerciseModalHandler}
                                   currentExercise={currentExercise}
                                   setCurrentExercise={openExerciseModalHandler}
                                   exercisesModalSetOpened={openExerciseModalHandler}/>
                </div>
            ) : '' }
            {(tagsModalIsOpened) ? (
                <div className="exercises-table-modal-layout-alt">
                    <Tags tagsModalSetOpened={openTagsModalHandler}/>
                </div>
            ) : ''}
        </div>
    )
}

export default Exercises;