import React, {useState} from "react";
import './style.css'

function TableList({current, table, setTable}) {

    const trainingList = [
        {key: 0, table: 'exercises', name: 'Упражнения'},
        {key: 1, table: 'trainings', name: 'Тренировки'},
        {key: 2, table: 'programs', name: 'Программы'},
        {key: 3, table: 'plans', name: 'Планы'},
        {key: 4, table: 'training-types', name: 'Типы тренировок'},
        {key: 5, table: 'training-progress', name: 'Прогресс тренировок'},
        {key: 6, table: 'plan-progress', name: 'Прогресс программ'},
        {key: 7, table: 'training-routine', name: 'История тренировок'}
    ];

    const diaryList = [
        {key: 0, table: 'common-symptoms', name: 'Группы симптомов'},
        {key: 1, table: 'detailed-symptoms', name: 'Подробные симптомы'},
        {key: 2, table: 'notes', name: 'Записи дневника'}
    ];

    const userList  = [
        {key: 0, table: 'information', name: 'Информация о пользователях'},
        {key: 1, table: 'credentials', name: 'Данные'}
    ];

    let list = useState([]);

    listHandling(current);

    function listHandling(current) {
        if (current === 'diary') {
            list = diaryList;
        } else if (current === 'trainings') {
            list = trainingList;
        } else if (current === 'users') {
            list = userList;
        } else {
            list = [];
            setTable('');
        }
    }

    return (
        <div className="table-list-wrapper">
            {list.map(item =>
                <div key={item.key} className="table-list-item">
                    <div className={table === item.table ? "table-list-button-alt" : "table-list-button"} onClick={() => setTable(item.table)}>
                        <p className="table-list-button-text">{item.name}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TableList;
