import React from "react";
import './style.css'

function Pagination({currentPage, setCurrentPage, totalPages}) {
    const pages = [];
    let counter = 0;

    if (totalPages === 1 || totalPages === 0) {
        pages.push(1);
    } else if (totalPages === 2) {
        pages.push(1, 2);
    } else if (totalPages === 3) {
        pages.push(1, 2, 3);
    } else if (totalPages === 4) {
        if (currentPage === 1) {
            pages.push(1, 2, '...', 4);
        } else if (currentPage === 2 || currentPage === 3) {
            pages.push(1, 2, 3, 4);
        } else pages.push(1, '...', 3, 4);
    } else {
        if (currentPage === 1 || currentPage === 2) {
            pages.push(1, 2, 3, '...', totalPages);
        } else if (currentPage === 3) {
            pages.push(1, 2, 3, 4, '...', totalPages);
        } else if (currentPage === totalPages || currentPage === totalPages - 1) {
            pages.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
        } else if (currentPage === totalPages - 2) {
            pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages)
        } else {
            pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        }
    }

    function uniqMaker(page) {
        counter++;
        return counter + page;
    }

    const listItems = pages.map((page) =>
        (page === currentPage) ?
            (<li key={page}><a className='pagination-current' href='#'  onClick={() => setCurrentPage(page)}>{page}</a></li>) :
            (page !== '...') ?
                (<li key={page}><a className='pagination-simple' href='#'  onClick={() => setCurrentPage(page)}>{page}</a></li>) :
                (<li key={uniqMaker(page)}><div className='pagination-simple'>{page}</div></li>)
    );

    return (
        <div className='pagination'>
            <ul className='pagination-list'>{listItems}</ul>
        </div>
    );

}

export default Pagination;