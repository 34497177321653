import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";
import './style.css';

function ExercisesModal({functionType, setFunctionType, currentExercise, setCurrentExercise, exercisesModalSetOpened}) {

    const [tag, setTag] = useState('');
    const [tagList, setTagList] = useState([]);
    const [preview, setPreview] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [duration, setDuration] = useState('');

    const requestMapping = api.requestMapping;
    const id = currentExercise.id;

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/tags/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTagList(res.data.result);
            } catch (error) {
                alert('Произошла ошибка при загрузке данных. ' + error);
            }
        })();
    }, []);

    function getTag(tagName) {
        for (let i = 0; i < tagList.length; i++) {
            if (tagList[i].tagName === tagName) {
                return tagList[i];
            }
        }
        return tagList[0];
    }

    function closeModalHandler() {
        exercisesModalSetOpened(false);
        setCurrentExercise('');
        setFunctionType('');
    }

    const previewHandler = (preview) => {
        setPreview(preview.target.value);
    }

    const titleHandler = (title) => {
        setTitle(title.target.value);
    }

    const descriptionHandler = (description) => {
        setDescription(description.target.value);
    }

    const linkHandler = (videoLink) => {
        setVideoLink(videoLink.target.value);
    }

    const durationHandler = (duration) => {
        setDuration(duration.target.value);
    }

    const tagHandler = (tag) => {
        setTag(tag.target.value);
    }

    const createExercise = async (tag, preview, title, description, videoLink, duration) => {
        await axios.post(requestMapping + '/training/admin/exercises/new',
            { tagId : tag, preview: preview, title: title, description: description, videoLink: videoLink, duration: duration },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Упражнение создано успешно!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    const editExercise = async (id, tag, preview, title, description, videoLink, duration) => {
        await axios.put(requestMapping + '/training/admin/exercises',
            { id: id, tagId : tag, preview: preview, title: title, description: description, videoLink: videoLink, duration: duration },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Упражнение "' + id + '" успешно отредактировано!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="exercises-modal-wrapper">
            <div className="exercises-modal-header">
                {functionType === 'create' ? <div className="exercises-modal-name">Создать упражнение</div> :
                    <div className="exercises-modal-name">Редактировать упражнение</div>
                }
                <div className="exercises-modal-close-item">
                    <button className="exercises-modal-close-button" onClick={closeModalHandler}>
                        <img className="exercises-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="exercises-modal-main">
                <div className="exercises-handler">
                    <div className="exercises-label">Тэг</div>
                    <select className="exercises-select" onChange={tagHandler}>
                        {tagList.map(item =>
                            <option key={item.id}
                                    value={item.id}
                                    selected={item.id === getTag(currentExercise.tagName).id}>
                                {item.id + ' - ' + item.tagName}
                            </option>
                        )}
                    </select>
                </div>
                <div className="exercises-handler">
                    <div className="exercises-label">Ссылка на превью упражнения</div>
                    <input className="exercises-input" onChange={previewHandler} placeholder={currentExercise.preview} value={preview} type='text' required/>
                </div>
                <div className="exercises-handler">
                    <div className="exercises-label">Название упражнения</div>
                    <input className="exercises-input" onChange={titleHandler} placeholder={currentExercise.title} value={title} type='text' required/>
                </div>
                <div className="exercises-handler">
                    <div className="exercises-label">Описание упражнения</div>
                    <textarea className="exercises-textarea" onChange={descriptionHandler} placeholder={currentExercise.description} value={description}/>
                </div>
                <div className="exercises-handler">
                    <div className="exercises-label">Ссылка на видео упражнения</div>
                    <input className="exercises-input" onChange={linkHandler} placeholder={currentExercise.videoLink} value={videoLink} type='text' required/>
                </div>
                <div className="exercises-handler">
                    <div className="exercises-label">Длительность упражнения (сек.)</div>
                    <input className="exercises-input" onChange={durationHandler} placeholder={currentExercise.duration} value={duration} type='text' required/>
                </div>
            </div>
            <div className="exercises-modal-footer">
                <button className="exercises-modal-confirm" onClick={
                    functionType === 'create' ? () => createExercise((tag === '' ? tagList[0].id : tag),
                            preview, title, description, videoLink, duration) :
                        () => editExercise(id,
                            (tag === '' ? getTag(currentExercise.tagName).id : tag),
                            (preview === '' ? currentExercise.preview : preview),
                            (title === '' ? currentExercise.title : title),
                            (description === '' ? currentExercise.description : description),
                            (videoLink === '' ? currentExercise.videoLink : videoLink),
                            (duration === '' ? currentExercise.duration : duration))}>Сохранить</button>
            </div>
        </div>
    );
}

export default ExercisesModal;