import React, {useEffect, useState} from "react";
import api from "../../../../../../api.json";
import axios from "axios";
import "./style.css";
import backIcon from "../../../../../../templates/icons/back-icon.png";
import closeIcon from "../../../../../../templates/icons/close-window.png";

function PartsModal({functionType, setFunctionType, currentModule, setCurrentModule, currentCategory, currentTrainingId, partsModalSetOpened}) {

    const requestMapping = api.requestMapping;
    const [exercisesList, setExercisesList] = useState([]);
    const [alternativeExercisesList, setAlternativeExercisesList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [flag, setFlag] = useState(false);
    const [commonSymptoms, setCommonSymptoms] = useState([]);
    const [detailedSymptoms, setDetailedSymptoms] = useState([]);
    const [allDetailedSymptoms, setAllDetailedSymptoms] = useState([]);
    const [allExercises, setAllExercises] = useState([]);
    const [symptomsIds] = useState([]);
    const [chosenSymptoms, setChosenSymptoms] = useState([]);

    const [exercise, setExercise] = useState('');
    const [alternativeExercise, setAlternativeExercise] = useState('');

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/tags/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTagList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о тэгах. ' + error.response.data.message);
            }
        })();
    }, [partsModalSetOpened])

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/diary/admin/common/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setCommonSymptoms(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о симптомах. ' + error.response.data.message);
            }
        })();
    }, [partsModalSetOpened])

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/diary/admin/detailed/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setAllDetailedSymptoms(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных о подробных симптомах. ' + error.response.data.message);
            }
        })();
    }, [partsModalSetOpened])

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/exercises/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setAllExercises(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных об упражнениях. ' + error.response.data.message);
            }
        })();
    }, [partsModalSetOpened])

    const getExercises = async (tag, type) => {
        try {
            const res = await axios.get(requestMapping + '/training/admin/exercises/?tag-id=' + tag,
                { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
            if (type === 'main') {
                setExercisesList(res.data.result);
            } else if (type === 'alt') {
                setAlternativeExercisesList(res.data.result);
            }
        } catch (error) {
            window.alert('Ошибка при загрузке списка упражнений. ' + error);
        }
    }

    useEffect(()=> {
        chosenSymptomsHandling();
    }, [flag === true])

    const getDetailedSymptoms = async (commonSymptom) => {
        try {
            const res = await axios.get(requestMapping + '/diary/admin/detailed/?category=' + currentCategory +
                '&common-id=' + commonSymptom,
                { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setDetailedSymptoms(res.data.result);
        } catch (error) {
            window.alert('Ошибка при загрузке списка подробных симптомов. ' + error);
        }
    }

    const createModule = async (trainingId, exerciseId, symptoms, alternativeExerciseId) => {
        if (exerciseId === '') {
            alert('Проверьте правильность выбора упражнения!');
        } else {
            await axios.post(requestMapping + '/training/admin/modules/new',
                { trainingId: trainingId, exerciseId: exerciseId,
                    symptoms: symptoms, alternativeExerciseId: alternativeExerciseId },
                { headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                    }})
                .then((response) => {
                    window.alert('Модуль создан успешно!');
                    closeModalHandler();
                })
                .catch((error) => window.alert(error.response.data.message))
        }
    }

    const editModule = async (id, trainingId, exerciseId, symptoms, alternativeExerciseId) => {
        await axios.put(requestMapping + '/training/admin/modules',
            { id: id, trainingId: currentTrainingId, exerciseId: exerciseId,
                symptoms: symptoms, alternativeExerciseId: alternativeExerciseId },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Модуль "' + id + '" успешно отредактирован!');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    function chosenSymptomsHandling() {
        let arr = [];
        for (let i = 0; i < symptomsIds.length; i++) {
            for (let j = 0; j < allDetailedSymptoms.length; j++) {
                if (allDetailedSymptoms[j].detailedId == symptomsIds[i]) {
                    arr.push(allDetailedSymptoms[j]);
                }
            }
        }
        setChosenSymptoms(arr);
        setFlag(false);
    }

    function getExerciseName (exerciseId) {
        for (let i = 0; i < allExercises.length; i++) {
            if (allExercises[i].id == exerciseId) {
                return allExercises[i].id + '-' + allExercises[i].title;
            }
        }
    }

    function closeModalHandler() {
        partsModalSetOpened(false);
        setCurrentModule('');
        setFunctionType('');
    }

    const mainTagChange = (tag) => {
        getExercises(tag.target.value, 'main');
    }

    const altTagChange = (tag) => {
        getExercises(tag.target.value, 'alt');
    }

    const exerciseChange = (exercise) => {
        setExercise(exercise.target.value);
    }

    const altExerciseChange = (exercise) => {
        setAlternativeExercise(exercise.target.value);
    }

    const commonGroupChange = (common) => {
        getDetailedSymptoms(common.target.value);
    }

    const detailedSymptomsAdd = (detailed) => {
        symptomsIds.push(detailed.target.value);
        setFlag(true);
    }

    const detailedDeleting = (detailed) => {
        symptomsIds.splice(detailed, 1);
        setFlag(true);
    }

    return (
        <div className="parts-modal-layout">
            <div className="parts-modal-outer-header" >
                <button className="parts-modal-back-button" onClick={closeModalHandler}>
                    <img className="parts-modal-back-icon" src={backIcon}/>
                </button>
                {(functionType === 'create') ?
                    (<div className="parts-modal-window-name">Добавить модуль</div>) :
                    (<div className="parts-modal-window-name">Редактировать модуль</div>)}
            </div>
            <div className="parts-modal-main">
                <fieldset className="parts-modal-main-container-outer">
                    <legend className="parts-modal-main-container-label">Упражнения</legend>
                    <fieldset className="parts-modal-exercises-container">
                        <legend className="parts-modal-exercises-legend">Основное упр-е</legend>
                        <div className="tag-label">Список тэгов</div>
                        <select className="parts-modal-select" onChange={mainTagChange}  defaultValue={'-'}>
                            <option value="-" disabled>-</option>
                            {tagList.map(item =>
                                    <option key={item.id}
                                            value={item.id}>
                                        {item.id + ' - ' + item.tagName}
                                    </option>
                            )}
                        </select>
                        <div className="tag-label">Список упражнений</div>
                        <select className="parts-modal-select" onChange={exerciseChange}  defaultValue={'-'}>
                            <option value="-" disabled>-</option>
                            {exercisesList.map(item =>
                                    <option key={item.id}
                                            value={item.id}>
                                        {item.id + ' - ' + item.title}
                                    </option>
                            )}
                        </select>
                    </fieldset>

                    <fieldset className="parts-modal-exercises-container">
                        <legend className="parts-modal-exercises-legend">Альтернативное упр-е</legend>
                        <div className="tag-label">Список тэгов</div>
                        <select className="parts-modal-select" onChange={altTagChange}  defaultValue={'-'}>
                            <option value="-" disabled>-</option>
                            {tagList.map(item =>
                                <option key={item.id}
                                        value={item.id}>
                                    {item.id + ' - ' + item.tagName}
                                </option>
                            )}
                        </select>
                        <div className="tag-label">Список упражнений</div>
                        <select className="parts-modal-select" onChange={altExerciseChange}  defaultValue={'-'}>
                            <option value='-' disabled>-</option>
                            {alternativeExercisesList.map(item =>
                                <option key={item.id}
                                        value={item.id}>
                                    {item.id + ' - ' + item.title}
                                </option>
                            )}
                        </select>
                    </fieldset>
                </fieldset>
                <fieldset className="parts-modal-main-container-outer">
                    <legend className="parts-modal-main-container-label">Симптомы</legend>
                    <div className="tag-label">Список групп симптомов</div>
                    <select className="parts-modal-select" onChange={commonGroupChange} defaultValue={'-'}>
                        <option value="-" disabled>-</option>
                        {commonSymptoms.map(item =>
                            <option key={item.commonId}
                                    value={item.commonId}>
                                {item.commonId + ' - ' + item.commonNameRus}
                            </option>
                        )}
                    </select>
                    <div className="tag-label">Список подробных симптомов</div>
                    <select className="parts-modal-select" onChange={detailedSymptomsAdd} defaultValue={'-'}>
                        <option value="-" disabled>-</option>
                        {detailedSymptoms.map(item =>
                            <option key={item.detailedId}
                                    value={item.detailedId}>
                                {item.detailedId + ' - ' + item.detailedNameRus}
                            </option>
                        )}
                    </select>
                </fieldset>
                <fieldset className="parts-modal-main-container-outer">
                    <legend className="parts-modal-main-container-label">Список выбранных</legend>
                    <div className="modules-chosen">
                        {chosenSymptoms.map((item, index) =>
                            <div className="modules-chosen-symptom">
                                <div key={index} className="modules-chosen-symptom-item">{item.detailedId + ' - ' + item.detailedNameRus}</div>
                                <div className="modules-chosen-symptom-delete" onClick={() => detailedDeleting(index)}>
                                    <img className="modules-chosen-symptom-delete-icon" src={closeIcon}/>
                                </div>
                            </div>)}
                    </div>
                </fieldset>
            </div>
            <div className="parts-modal-footer">
                <button className="modules-confirm" onClick={
                    functionType === 'create' ? () => createModule(currentTrainingId,
                            (exercise), symptomsIds,
                            (alternativeExercise === '' ? null : alternativeExercise)) :
                        () => editModule(currentModule.id, currentTrainingId,
                            (exercise === '' ? currentModule.exerciseId : exercise),
                            (symptomsIds.length === 0 ? currentModule.symptoms : symptomsIds),
                            (alternativeExercise === '' ? currentModule.exerciseId : alternativeExercise)
                        )}>Сохранить</button>
            </div>
        </div>
    )
}

export default PartsModal;