import React, {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../../../pagination";
import './style.css';
import api from "../../../../../api.json";
import SearchByUserId from "../../../search-by-user-id";

function PlanProgress() {

    const requestMapping = api.requestMapping;
    const [planProgressList, setPlanProgressList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentUser, setCurrentUser] = useState('');
    const [searchState, setSearchState] = useState(false);

    let totalPages = Math.ceil(planProgressList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get( requestMapping + '/training/admin/plan-progresses/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setPlanProgressList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных. ' + error);
            }
        })();
    }, [currentUser === '']);

    const pathVariable = (currentUser) => {
        setCurrentUser(currentUser.target.value);
    }

    const getPlanProgressByUserId = async (currentUserId) => {
        await axios.get( requestMapping + '/training/admin/plan-progresses/' + currentUserId,
            { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                setPlanProgressList([response.data.result]);
            })
            .catch((error) => {
                setSearchState(false);
                setCurrentUser('');
                window.alert('Произошла ошибка при выполнении запроса. ' + error.response.data.code + " " +  error.response.data.message);
            });
    }

    useEffect(() => {
        setItemList(planProgressList.slice((currentPage - 1) * 10, currentPage * 10))
    },[planProgressList, currentPage])

    function handlePage(page) {
        setCurrentPage(page);
    }

    function handleSearch() {
        getPlanProgressByUserId(currentUser);
        setSearchState(true);
    }

    return (
        <div className="plan-progress-table-inner-wrapper">
            <div className="plan-progress-table-modal-layout">
                <div className="plan-progress-table-inner-header">
                    <div className="plan-progress-table-header">
                        <div className="plan-progress-id">id</div>
                        <div className="plan-progress-user-id">id пользователя</div>
                        <div className="plan-progress-plan-id">id плана</div>
                        <div className="plan-progress-plan-start-date">Старт плана</div>
                        <div className="plan-progress-plan-end-date">Конец плана</div>
                        <div className="plan-progress-plan-stage">Этап плана</div>
                        <div className="plan-progress-program-id">id программы</div>
                        <div className="plan-progress-program-start-date">Старт программы</div>
                        <div className="plan-progress-program-end-date">Конец программы</div>
                    </div>
                </div>
                <div className="plan-progress-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="plan-progress-table-item">
                            <div className="plan-progress-id">{item.id}</div>
                            <div className="plan-progress-user-id">{item.userId}</div>
                            <div className="plan-progress-plan-id">{item.planId}</div>
                            <div className="plan-progress-plan-start-date">{item.startPlan}</div>
                            <div className="plan-progress-plan-end-date">{item.endPlan}</div>
                            <div className="plan-progress-plan-stage">{item.planStage}</div>
                            <div className="plan-progress-program-id">{item.programId}</div>
                            <div className="plan-progress-program-start-date">{item.startProgram}</div>
                            <div className="plan-progress-program-end-date">{item.endProgram}</div>
                        </div>
                    )}
                </div>
                <div className="plan-progress-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <SearchByUserId currentUser={currentUser}
                                    setCurrentUser={setCurrentUser}
                                    handleSearch={handleSearch}
                                    searchState={searchState}
                                    setSearchState={setSearchState}/>
                </div>
            </div>
        </div>
    )
}

export default PlanProgress;