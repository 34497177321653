import React, {useState} from "react";
import api from "../../../../../api.json";
import './style.css';
import axios from "axios";
import closeIcon from "../../../../../templates/icons/close-window.png";

function CredentialsModal({credentialsModalSetOpened}) {

    const requestMapping = api.requestMapping;
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[role, setRole] = useState('');
    const roleOptions = [
        {key: 1, role: 'ADMIN', roleRus: 'Админ'},
        {key: 2, role: 'TRAINER', roleRus: 'Тренер'}
    ];

    function closeModalHandler() {
        credentialsModalSetOpened(false);
    }

    const emailHandler = (email) => {
        setEmail(email.target.value);
    }

    const passwordHandler = (password) => {
        setPassword(password.target.value);
    }

    const roleHandler = (role) => {
        setRole(role.target.value);
    }

    const createSuperUser = async (email, password, role) => {
        await axios.post(requestMapping + '/users/admin/create-super/new',
            { email: email, password: password, role : role },
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert('Новый пользователь зарегистрирован. Необходимо пройти по ссылке в отправленном на указанный адрес письме для завершения регистрации');
                closeModalHandler();
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="credentials-modal-wrapper">
            <div className="credentials-modal-header">
                <div className="credentials-modal-name">Создать привилегированного пользователя</div>
                <div className="credentials-modal-close-item">
                    <button className="credentials-modal-close-button" onClick={closeModalHandler}>
                        <img className="credentials-modal-close-icon" src={closeIcon}/>
                    </button>
                </div>
            </div>
            <div className="credentials-modal-main">
                <div className="credentials-email-handler">
                    <div className="credentials-email-label">email нового пользователя</div>
                    <input className="credentials-email-input" onChange={emailHandler} value={email} type='email' required/>
                </div>
                <div className="credentials-password-handler">
                    <div className="credentials-password-label">Пароль нового пользователя</div>
                    <input className="credentials-password-input" onChange={passwordHandler} value={password} type='text' required/>
                </div>
                <div className="credentials-role-label">Роль пользователя</div>
                <select className="role-select" onChange={roleHandler} defaultValue={'TRAINER'}>
                    {roleOptions.map(item =>
                        <option key={item.key} value={item.role}>{item.roleRus}</option>
                    )}
                </select>
            </div>
            <div className="credentials-modal-footer">
                <button className="credentials-modal-confirm" onClick={() => createSuperUser(email, password, (role === '' ? 'TRAINER' : role))}>Сохранить</button>
            </div>
        </div>
    );
}

export default CredentialsModal;