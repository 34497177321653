import React from "react";
import closeSearchById from "../../../templates/icons/close-window.png";
import './style.css';

function SearchByUserId({searchState, setSearchState, currentUser, setCurrentUser, handleSearch}) {

    const pathVariable = (currentUser) => {
        setCurrentUser(currentUser.target.value);
    }

    return(
        <div className="search-item">
            <label className="search-label">Поиск по id пользователя</label>
            <input className="search-input" value={currentUser} type="text" onChange={pathVariable}/>
            {searchState === false ? <button className="search-button" onClick={() => {
                currentUser === '' ? alert('Введите id пользователя') : handleSearch();
            }}>Поиск</button> : <button className="search-cancel-button" onClick={() => {
                setCurrentUser('');
                setSearchState(false);
            }}><img className="search-cancel-button-icon" src={closeSearchById}/></button>}
        </div>
    );
}

export default SearchByUserId;