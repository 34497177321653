import Exercises from "./trainings/exercises/table";
import Trainings from "./trainings/trainings/table";
import Plans from "./trainings/plans/table";
import Programs from "./trainings/programs/table";
import TrainingTypes from "./trainings/training-types/table";
import TrainingRoutine from "./trainings/training-routine/table";
import TrainingProgress from "./trainings/training-progress/table";
import PlanProgress from "./trainings/plan-progress/table";
import UserInfo from "./users/user-info/table";
import Credentials from "./users/credentials/table";
import CommonSymptoms from "./diary/common-symptoms/table";
import DetailedSymptoms from "./diary/detailed-symptoms/table";
import Notes from "./diary/notes/table";

class TableMapper {

    tableMap(table) {
        switch (table) {
            case 'exercises' : return <Exercises/>;
            case 'trainings' : return <Trainings/>;
            case 'plans' : return <Plans/>;
            case 'programs' : return <Programs/>;
            case 'training-types' : return <TrainingTypes/>;
            case 'training-routine' : return <TrainingRoutine/>
            case 'training-progress' : return <TrainingProgress/>
            case 'plan-progress' : return <PlanProgress/>
            case 'information' : return <UserInfo/>
            case 'credentials' : return <Credentials/>
            case 'common-symptoms' : return <CommonSymptoms/>
            case 'detailed-symptoms' : return <DetailedSymptoms/>
            case 'notes' : return <Notes/>
            default : return '';
        }
    }
}

export default TableMapper;