import React, {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../../../pagination";
import './style.css';
import api from "../../../../../api.json";

function UserInfo() {

    const requestMapping = api.requestMapping;
    const [userList, setUserList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(userList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get( requestMapping + '/users/admin/info/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setUserList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных пользователей. ' + error);
            }
        })();
    }, [])

    useEffect(() => {
        setItemList(userList.slice((currentPage - 1) * 10, currentPage * 10))
    },[userList, currentPage])

    function handlePage(page) {
        setCurrentPage(page);
    }

    return (
        <div className="user-table-inner-wrapper">
            <div className="user-table-modal-layout">
                <div className="user-table-inner-header">
                    <div className="user-table-header">
                        <div className="user-id">id</div>
                        <div className="user-name">Имя</div>
                        <div className="user-age">Возраст</div>
                        <div className="user-sex">Пол</div>
                        <div className="user-category">Категория</div>
                        <div className="user-pregnancy-count">Бер-ть по счету</div>
                        <div className="user-pregnancy-week">Неделя бер-ти</div>
                        <div className="user-birth-date">Дата родов</div>
                        <div className="user-weight-before">Вес до бер-ти</div>
                        <div className="user-weight-now">Вес сейчас</div>
                        <div className="user-height">Рост</div>
                        <div className="user-email">email</div>
                    </div>
                </div>
                <div className="user-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="user-table-item">
                            <div className="user-id">{item.id === null ? '---' : item.id}</div>
                            <div className="user-name">{item.name === null ? '---' : item.name}</div>
                            <div className="user-age">{item.age === null ? '---' : item.age}</div>
                            <div className="user-sex">{item.sex === null ? '---' : (item.sex === 'FEMALE' ? 'Ж' : 'М')}</div>
                            <div className="user-category">{item.category === null ? '---' : (item.category === 'POSTPARTUM' ? 'Восст-е' : 'Берем-ть')}</div>
                            <div className="user-pregnancy-count">{item.pregnancyCount === null ? '---' : item.pregnancyCount}</div>
                            <div className="user-pregnancy-week">{item.pregnancyWeek === null ? '---' : item.pregnancyWeek}</div>
                            <div className="user-birth-date">{item.estimatedBirthDate === null ? '---' : item.estimatedBirthDate.slice(0, 10)}</div>
                            <div className="user-weight-before">{item.weightBefore === null ? '---' : item.weightBefore}</div>
                            <div className="user-weight-now">{item.weightNow === null ? '---' : item.weightNow}</div>
                            <div className="user-height">{item.height === null ? '---' : item.height}</div>
                            <div className="user-email">{item.email === null ? '---' : item.email}</div>
                        </div>
                    )}
                </div>
                <div className="user-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="user-amount">Всего пользователей: <span className="user-amount-span">{userList.length}</span></div>
                </div>
            </div>
        </div>
    )
}

export default UserInfo;