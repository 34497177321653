import React, {useEffect, useState} from "react";
import api from "../../../../../api.json";
import axios from "axios";
import copyIcon from "../../../../../templates/icons/copy-link.png";
import editIcon from "../../../../../templates/icons/edit-icon.png";
import deleteIcon from "../../../../../templates/icons/delete-icon.png";
import Pagination from "../../../pagination";
import TrainingsModal from "../modal";
import './style.css';
import ModulesEditModal from "../training-modules/table";

function Trainings() {

    const requestMapping = api.requestMapping;
    const [trainingsList, setTrainingsList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [trainingsModalIsOpened, trainingsModalSetOpened] = useState(false);
    const [modulesModalIsOpened, modulesModalSetOpened] = useState(false);
    const [currentTraining, setCurrentTraining] = useState('');
    const [functionType, setFunctionType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    let totalPages = Math.ceil(trainingsList.length / 10);

    useEffect(()=> {
        (async () => {
            try {
                const res = await axios.get(requestMapping + '/training/admin/trainings/all',
                    { headers: {'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token') } });
                setTrainingsList(res.data.result);
            } catch (error) {
                window.alert('Ошибка при загрузке данных об тренировках. ' + error);
            }
        })();
    }, [trainingsModalSetOpened, currentTraining, modulesModalSetOpened])

    useEffect(() => {
        setItemList(trainingsList.slice((currentPage - 1) * 10, currentPage * 10))
    },[trainingsList, currentPage])

    function openModalHandler(functionType, state, current) {
        setFunctionType(functionType);
        setCurrentTraining(current);
        trainingsModalSetOpened(state);
    }

    function openModulesListHandler(state, current) {
        setCurrentTraining(current);
        modulesModalSetOpened(state);
    }

    function copyLinkHandler(link) {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                alert("Скопировано.");
            })
            .catch(() => {
                alert("Произошла ошибка.");
            });
    }

    function deleteHandler(currentTraining) {
        setCurrentTraining(currentTraining);
        if (window.confirm("Вы уверены, что хотите удалить тренировку с id " + currentTraining + "?")) {
            deleteTraining(currentTraining);
        } else {
            setCurrentTraining('');
        }
    }

    function handlePage(page) {
        setCurrentPage(page);
    }

    const deleteTraining = async (currentTraining) => {
        await axios.delete(requestMapping + '/training/admin/trainings/' + currentTraining,
            { headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('rocks-me-token')
                }})
            .then((response) => {
                window.alert("Тренировка " + currentTraining + " успешно удалена!");
                setCurrentTraining('');
            })
            .catch((error) => window.alert(error.response.data.message));
    }

    return (
        <div className="trainings-table-inner-wrapper">
            <div className="trainings-table-modal-layout">
                <div className="trainings-table-inner-header">
                    <div className="trainings-table-header">
                        <div className="trainings-id-header">id</div>
                        <div className="trainings-preview-header">Ссылка на превью</div>
                        <div className="trainings-title-header">Название тренировки</div>
                        <div className="trainings-duration-header">Длит-ть трен-ки (мин.)</div>
                        <div className="trainings-exercises-amount-header">Кол-во упражнений</div>
                        <div className="trainings-modules-edit-header">Редакт-е упражнений</div>
                        <div className="trainings-type-header">Тип тренировок</div>
                        <div className="trainings-category-header">Категория пользователей</div>
                        <div className="trainings-actions">Действия</div>
                    </div>
                </div>
                <div className="trainings-table-inner-main">
                    {itemList.map((item) =>
                        <div key={item.id} className="trainings-table-item">
                            <div className="trainings-id">{item.id}</div>
                            <div className="trainings-preview">
                                <div className="trainings-preview-link">
                                    <a className="preview-link-text" href={item.trainingPreview} target="_blank">
                                        {(item.trainingPreview == '') ? '' : 'Ссылка'}</a>
                                </div>
                                {(item.trainingPreview == '') ? '' :
                                    <div className="trainings-preview-copy-item">
                                        <button className="trainings-preview-copy-button"
                                                onClick={() => copyLinkHandler(item.trainingPreview)}>
                                            <img className="trainings-preview-copy-icon" src={copyIcon}/>
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="trainings-title">{item.trainingTitle}</div>
                            <div className="trainings-duration">{item.trainingDuration}</div>
                            <div className="trainings-exercises-amount">{item.modulesAmount}</div>
                            <div className="trainings-modules-edit">
                                <button className="trainings-modules-edit-button" onClick={() => openModulesListHandler(true, item)}>
                                    <img className="trainings-modules-edit-icon" src={editIcon}/>
                                </button>
                            </div>
                            <div className="trainings-type">{item.trainingType}</div>
                            <div className="trainings-category">{item.category === 'POSTPARTUM' ? 'Восстановление' : 'Беременные'}</div>
                            <div className="trainings-actions-item">
                                <button className="trainings-actions-item-edit-button" onClick={() => openModalHandler('edit', true, item)}>
                                    <img className="trainings-actions-edit-icon" src={editIcon}/>
                                </button>
                                <button className="trainings-actions-item-delete-button" onClick={() => deleteHandler(item.id)} >
                                    <img className="trainings-actions-delete-icon" src={deleteIcon}/>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="trainings-table-inner-footer">
                    <Pagination currentPage={currentPage} setCurrentPage={handlePage} totalPages={totalPages}/>
                    <div className="trainings-table-create">
                        <button className="trainings-table-create-button" onClick={() => openModalHandler('create', true, '')}>Создать тренировку</button>
                    </div>
                </div>
            </div>
            {(trainingsModalIsOpened) ? (
                <div className="trainings-table-modal-layout-alt">
                    <TrainingsModal functionType={functionType}
                                    setFunctionType={openModalHandler}
                                    currentTraining={currentTraining}
                                    setCurrentTraining={openModalHandler}
                                    trainingsModalSetOpened={openModalHandler}/>
                </div>
            ) : '' }
            {(modulesModalIsOpened) ? (
                <div className="trainings-table-modal-layout-alt">
                    <ModulesEditModal currentTraining={currentTraining}
                                      setCurrentTraining={openModulesListHandler}
                                      modulesModalSetOpened={openModulesListHandler}/>
                </div>
            ) : ''}
        </div>
    )
}

export default Trainings;