import './index.css'
import {Navigate, Route, Routes} from "react-router-dom";
import Form from "./components/form";
import AppMain from "./components/app-main";
import {useEffect, useState} from "react";

function App() {

    const [name, setName] = useState('');
    const [authorized, setAuthorized] = useState(false)

    useEffect(() => {
        checkAuthority();
    }, [authorized])

    function authorizationHandler (status) {
        setAuthorized(status);
    }

    function nameHandler(name) {
        setName(name);
    }

    function checkAuthority() {
        return localStorage.getItem("rocks-me-token") !== null
            && localStorage.getItem("rocks-me-name") !== null;
    }

    return (
        <>
            <Routes>
                <Route path={'/login'} element={checkAuthority() ? <Navigate to ='/main'/>:<Form setAuthorised={authorizationHandler} setName={nameHandler} />}/>
                <Route path={'/main'} element={checkAuthority() ? <AppMain setAuthorized={authorizationHandler}/> : <Navigate to='/login'/>}/>
                <Route path='*' element={checkAuthority() ? <Navigate to ='/main'/> : <Navigate to='/login'/>}/>
            </Routes>
        </>
    );
}

export default App;
