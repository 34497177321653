import React from "react";
import Profile from "../profile";
import './style.css';

function Header({current, setCurrent, setAuthorized}) {

    const mode = [
        {key: 0, state: 'trainings', name: 'Тренировки'},
        {key: 1, state: 'diary', name: 'Дневник самочувствия'},
        {key: 2, state: 'users', name: 'Пользователи'},
    ]

    function authorizationHandler(status) {
        setAuthorized(status);
    }

    return (
        <div className="header-wrapper">
            <div className="header-main">
                <div className="header-logo" onClick={() => setCurrent('')}><span>Rocks me</span></div>
                <div className="header-menu">
                    {mode.map(item =>
                        <div key={item.key} className="header-item">
                            <div className={current === item.state ? "header-button-item-alt" : "header-button-item"} onClick={() => setCurrent(item.state)}>
                                <p className="header-item-name">{item.name}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="header-profile">
                <Profile setAuthorized={authorizationHandler}/>
            </div>
        </div>
    )
}

export default Header;